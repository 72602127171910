import React from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import '../Form.scss'
import http from '../../services/HttpService'
import loader from '../../images/loader.gif'
import SimpleReactValidator from 'simple-react-validator';
import ToastUtil from "../../utils/ToastUtil";
import {connect} from "react-redux";
import store from '../store/index'
import {toggleContactUsModal} from "../action/contactUsModalAction";
import ReCAPTCHA from "react-google-recaptcha";
import {RECAPTA_KEY} from "../../constants/constants";

class ContactUsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payload: {name: "Anonymous", client: "Streamly"},
            isInProgress: false,
            message: '',
            isRobot: true
        };

        this.validator = new SimpleReactValidator({
            element: (message) => {
                return <span className="error">{message}</span>
            }
        });
    }

    changeHandler = (event) => {
        let {payload} = this.state;
        payload[event.target.name] = event.target.value;
        this.setState({payload})
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    closeModal = () => {
        store.dispatch(toggleContactUsModal(false));
    };

    onCaptchaChange = (value) => {
        this.setState({isRobot: false})
    };

    onCaptchaExpired = () => {
        this.setState({isRobot: true})
    };

    submit = (e) => {
        e.preventDefault();
        let {payload, isRobot} = this.state;
        if (isRobot) {
            ToastUtil.error("Vänligen verifiera att du inte är robot.");
            return;
        }
        this.toggleInProgress(true);
        http.post("/api/streamly/suggestion", payload)
            .then((response) => {
                let {status, errors} = response.data;
                if (status) {
                    ToastUtil.success("Dina idéer och feedback är mycket uppskattade");
                } else {
                    ToastUtil.error(errors[0])
                }
            })
            .catch((error) => {
                console.log(error.toJSON)
            })
            .finally(() => {
                this.toggleInProgress(false)
            })

    };

    render() {
        let {isInProgress} = this.state;
        let {contactUsModal = {}} = this.props;
        return (
            <Modal
                show={contactUsModal.isOpen}
                onHide={this.closeModal} size="md"
                aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span className="streamly_icon"></span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Hur tycker du att vi kan förbättra Streamly? Vi vill gärna höra vad du tycker.


                    </p>
                    <Form onSubmit={this.submit}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className={"font-weight-bold"}>E-postadress</Form.Label>
                            <Form.Control type="email" name="from" onChange={this.changeHandler}/>
                            {this.validator.message('from', this.state.from, 'required|email')}
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label className={"font-weight-bold"}>Förslag</Form.Label>

                            <textarea name="body" className={"form-control"} rows="6"
                                      onChange={this.changeHandler}/>
                            {this.validator.message('body', this.state.body, 'required')}
                        </Form.Group>
                        <ReCAPTCHA
                            size={'compact'}
                            theme={'dark'}
                            sitekey={RECAPTA_KEY}
                            onChange={this.onCaptchaChange}
                            onExpired={this.onCaptchaExpired}
                        />
                        <Button variant="primary cyan-background" type="submit">SKICKA</Button>&nbsp;
                        {isInProgress ? <img src={loader}/> : ''}
                    </Form>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {contactUsModal: state.contactUsModal}
};

export default connect(mapStateToProps)(ContactUsModal)