import React from "react";
import "./filterBar.scss";
import "../../constants/constants";
import http from "../../services/HttpService";
import _ from 'lodash';
import {getParams, redirectTo} from "../../utils/UrlUtil";
import {
    getSelectedCountry,
    getSelectedProviders,
    isStreamHomePage,
    isTopListPage,
    reloadPage,
    setSelectedProviders
} from "../../utils/Util";

class Providers extends React.Component {
    constructor() {
        super();
        let params = getParams();
        this.state = {
            providers: [],
            selectedProviders: getSelectedProviders(),
            isOpen: false
        };
    }

    toggleProvider = (provider) => {
        // let {selectedProviders} = this.state;
        // if (_.some(selectedProviders, ["name", provider.name])) {
        //     _.remove(selectedProviders, {name: provider.name})
        // } else {
        //     selectedProviders.push(provider)
        // }
        // let providers = selectedProviders.map((provider) => {
        //     return {
        //         name: provider.name,
        //         label: provider.label
        //     }
        // });
        // setSelectedProviders(providers);

        let {selectedProviders} = this.state;
        if (_.some(selectedProviders, ["name", provider.name])) {
            setSelectedProviders([]);
        } else {
            setSelectedProviders([{
                name: provider.name,
                label: provider.label
            }]);

        }
        setSelectedProviders([{
            name: provider.name,
            label: provider.label
        }]);
        // window.location.href = '/genre/all';


        if (isStreamHomePage() || isTopListPage()) {
            redirectTo('/genre/all');
        } else {
            reloadPage();
        }
    };

    toggleMenu = () => {
        this.setState({isOpen: !this.state.isOpen})
    };

    isProviderSelected = (provider) => {
        if (provider) {
            let {selectedProviders} = this.state;
            return _.some(selectedProviders, ["name", provider.name]);
        }
    };

    loadProviders = () => {
        http
            .get("/api/es/providers/active/" + getSelectedCountry())
            .then((response) => {
                let {providers} = response.data.content;
                this.setState({providers});
                if (this.props.onProvidersLoaded) {
                    this.props.onProvidersLoaded(providers)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    clear = () => {
        setSelectedProviders([]);
        reloadPage();
    }

    componentDidMount() {
        this.loadProviders();
    }

    render() {
        let {providers, selectedProviders, isOpen} = this.state;
        providers = _.orderBy(providers, "tabIndex");

        return (
            <>
                <button class={`menu-button select-source-menu-button ${isOpen ? 'extended' : ''}`}
                        onClick={this.toggleMenu}>
                    Byt Leverantör 
                    {/* {selectedProviders.length ? `(${selectedProviders.length})` : ''} */}
                </button>

                <div className="select-source select-drop-down"
                     style={{'display': isOpen ? 'block' : 'none'}}>
                    <div className="even-columns">
                        {
                            providers.map((provider) => {
                                return <>
                                    <input type="checkbox"
                                           checked={this.isProviderSelected(provider) ? 'checked' : ''}
                                           onClick={() => {
                                               this.toggleProvider(provider)
                                           }
                                           } className="genre-checkbox" id={provider.name}
                                           value={provider.name}/>
                                    <label for={provider.name}>{provider.label}</label>
                                </>
                            })
                        }
                    </div>
                    <button onClick={this.clear}
                            className="button white-background button-thin"
                            id="select-source-button">Klar
                    </button>
                </div>
            </>
        );
    }
}

export default Providers;
