import React from "react";
import "../../MainContent.scss";
// import "../../components/../common.scss";
import "../../../components/program/ProgramDetail.scss";
import "../../program/TableLayout.scss";
import {
    getAdsLabel,
    getHeaderDesktopAds,
    getSelectedCountry,
    getTopDesktopAds,
    getTopMobileAds,
    setProviderAndRedirectTo,
} from "../../../utils/Util";
import loader from "../../../images/loaderLarge.gif";
import {Helmet} from "react-helmet";
import {isDesktop, isMobileDevice, isTableDevice} from "../../../utils/DeviceUtil";
import http from "../../../services/HttpService";
import _ from 'lodash'
import TopStreamBlock from "./TopStreamBlock";
import {getTranslatedText} from "../../../utils/Translation";

class TopStreamHome extends React.Component {

    constructor(props) {
        super(props);
        let providers = [];
        for (let i = 0; i < 10; i++) {
            providers.push({})
        }
        this.state = {providers: providers};
    }

    loadProviders = async () => {
        let response = await http
            .get("/api/es/providers/active/" + getSelectedCountry())
        let {providers} = response.data.content;
        this.setState({providers: _.orderBy(providers, "tabIndex")})
    }

    componentDidMount() {
        this.loadProviders();
    }

    getStreams = () => {
        let {providers} = this.state;
        let selectedCountry = getSelectedCountry();
        return (
            <div>
                {/*<ProgramRecommendations/>*/}
                {/*first ad*/}
                {/*{getHeaderMobileAds()}*/}

                <div className="stream-listing-page">
                    {/*<ProvidersList/>*/}
                    {getHeaderDesktopAds()}
                    
                    {isTableDevice() ? <div id="cncpt-tab_lb1"></div> : ''}
                    {this.setTitleAndMetadata()}
                    {getTopDesktopAds()}
                    {getTopMobileAds()}
                    {
                        providers.map((provider, index) => {
                            return <div className="provider-wrapper">
                                <div className="icon-container">
                                    <a href={`/genre/${provider.name}`}
                                       onClick={(e) => {
                                           e.preventDefault()
                                           setProviderAndRedirectTo(provider, '/genre/provider')
                                       }
                                       }
                                       className={`${provider.name}_full`}></a>
                                </div>
                                {provider && provider.name ?
                                    <TopStreamBlock size={10} containerClass={`${provider.name}-block`}
                                                    streamType={"movie"}
                                                    viewAllUrl={'/genre/movies?sorting=topList'}
                                                    heading={`${getTranslatedText(selectedCountry, "top.ten.film")}`}
                                                    providers={[provider]}/> : null}
                                {provider && provider.name ?
                                    <TopStreamBlock size={10} containerClass={`${provider.name}-block`}
                                                    streamType={"tv-show"}
                                                    viewAllUrl={'/genre/series?sorting=topList'}
                                                    heading={`${getTranslatedText(selectedCountry, "top.ten.series")}`}
                                                    providers={[provider]}/> : null}

                                {index === 0 ? <div className="blog-ads">
                                    {getAdsLabel()}
                                    {isDesktop() ? <div id="cncpt-lb2"></div> : ''}
                                    {isTableDevice() ? <div id="cncpt-tab_lb2"></div> : ''}
                                    {isMobileDevice() ? <div id="cncpt-mob2"></div> : ''}
                                </div> : null}
                                {index === 1 ? <div className="blog-ads">
                                    {getAdsLabel()}
                                    {isDesktop() ? <div id="cncpt-lb3"></div> : ''}
                                    {isTableDevice() ? <div id="cncpt-tab_lb3"></div> : ''}
                                    {isMobileDevice() ? <div id="cncpt-mob3"></div> : ''}
                                </div> : null}
                            </div>
                        })
                    }

                </div>
            </div>
        );
    };

    getLoader = () => {
        if (this.state.hasMoreChannels) {
            return (
                <div className=" text-center">
                    <img src={loader} alt=" loader"/>
                </div>
            );
        }
    };

    setTitleAndMetadata = () => {
        let title = "Streamly";
        let description = "Streamly är tjänsten för dig som enkelt vill kunna hänga med i streamingutbudet. Du ska alltid kunna hitta något som passar just dig, just nu. Välj eller ta bort kategorier så det passar dig, fördjupa dig i intervjuer, eller följ dina vänners rekommendationer.";
        return (
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
            </Helmet>
        );
    };

    render() {
        return (
            <div>
                {this.setTitleAndMetadata()}
                {this.getStreams()}
                {this.getLoader()}
            </div>
        );
    }
}

export default TopStreamHome;
