import {CLOSE_CONTACT_MODAL, OPEN_CONTACT_MODAL} from "../action/contactUsModalAction";

const initialState = {
    isOpen: false
};
const contactUsModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_CONTACT_MODAL:
            return {...state, isOpen: true};
        case CLOSE_CONTACT_MODAL:
            return {...state, isOpen: false};
        default:
            return state;
    }
};
export default contactUsModalReducer;