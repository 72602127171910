import {CLOSE_MAIN_MENU, OPEN_MAIN_MENU} from "../action/mainMenuAction";

const initialState = {
    isOpen: false
};
const mainMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_MAIN_MENU:
            return {...state, isOpen: true};
        case CLOSE_MAIN_MENU:
            return {...state, isOpen: false};
        default:
            return state;
    }
};
export default mainMenuReducer;