import React from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import '../Form.scss'
import http from '../../services/HttpService'
import loader from '../../images/loader.gif'
import SimpleReactValidator from 'simple-react-validator';
import ToastUtil from "../../utils/ToastUtil";
import ReCAPTCHA from "react-google-recaptcha";
import {RECAPTA_KEY} from "../../constants/constants";

class TellToFriend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payload: {},
            isInProgress: false,
            message: '',
            isRobot: true
        };

        this.validator = new SimpleReactValidator({
            element: (message) => {
                return <span className="error">{message}</span>
            }
        });
    }

    changeHandler = (event) => {
        let {payload} = this.state;
        payload[event.target.name] = event.target.value;
        this.setState({payload});
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    onCaptchaChange = (value) => {
        this.setState({isRobot: false})
    };

    onCaptchaExpired = () => {
        this.setState({isRobot: true})
    };

    submit = (e) => {
        e.preventDefault();
        let {payload} = this.state;
        let {program} = this.props;
        payload.programId = program.id;
        this.toggleInProgress(true);
        http.post("/api/streamly/tellToFriend", payload)
            .then((response) => {
                let {status, errors} = response.data;
                if (status) {
                    ToastUtil.success("Skicka ditt meddelande till din vän.");
                } else {
                    ToastUtil.error(errors[0])
                }
            })
            .catch((error) => {
                console.log(error.toJSON)
            })
            .finally(() => {
                this.toggleInProgress(false)
            })

    };

    render() {
        let {isInProgress} = this.state;
        let {show} = this.props;
        return (
            <Modal
                {...this.props}
                show={show}
                size="md"
                aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span className="streamly_icon"></span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Tipsa en vän om detta!
                    </p>
                    <Form onSubmit={this.submit}>
                        <Form.Label className={"font-weight-bold"}>Ditt namn</Form.Label>
                        <Form.Control type="text" name="yourName" onChange={this.changeHandler}/>
                        {this.validator.message('email', this.state.yourName, 'required|email')}
                        <Form.Label className={"font-weight-bold"}>Din e-postadress</Form.Label>
                        <Form.Control type="email" name="yourEmail" onChange={this.changeHandler}/>
                        {this.validator.message('email', this.state.yourEmail, 'required|email')}
                        <Form.Label className={"font-weight-bold"}>Din väns e-postadress</Form.Label>
                        <Form.Control type="email" name="friendEmail" onChange={this.changeHandler}/>
                        {this.validator.message('email', this.state.friendEmail, 'required|email')}
                        <ReCAPTCHA
                            size={'compact'}
                            theme={'dark'}
                            sitekey={RECAPTA_KEY}
                            onChange={this.onCaptchaChange}
                            onExpired={this.onCaptchaExpired}
                        />
                        <Button variant="primary cyan-background" type="submit">SKICKA</Button>&nbsp;
                        {isInProgress ? <img src={loader}/> : ''}
                    </Form>
                </Modal.Body>
            </Modal>
        )
    }
}

export default TellToFriend