import React from "react";
import http from "../../../services/HttpService";
import {getParams} from "../../../utils/UrlUtil";
import {
    findFavouriteByProgramId,
    findReminderByProgramId,
    getLiveEndDateFilter,
    getLiveStartDateFilter,
    getSelectedCountry,
    getSelectedProviders,
    isSportsLivePage
} from "../../../utils/Util";
import _ from 'lodash'
import {isDesktop, isMobileDevice, isTableDevice} from "../../../utils/DeviceUtil";
import ReactPaginate from 'react-paginate';
import StreamCard from "../StreamCard";

class Streams extends React.Component {
    constructor(props) {
        super(props);
        const params = getParams();
        let programs = [];
        for (let i = 1; i <= 32; i++) {
            programs.push({});
        }
        this.state = {
            programs: programs,
            favourites: [],
            reminders: [],
            pageCount: 0,
            totalElements: 0,
            selectedPage: params.get("p") || 0
        };
    }

    loadFavourites = () => {
        http
            .get("/api/es/favourites/streamly/me")
            .then((response) => {
                let { favourites } = response.data.content;
                this.setState({ favourites });
            })
            .catch((error) => {
                console.log(error);
            })
    };

    loadReminders = () => {
        if (isSportsLivePage()) {
            http
                .get("/api/streamly/reminders/me")
                .then((response) => {
                    let { reminders } = response.data.content;
                    this.setState({ reminders });
                })
                .catch((error) => {
                    console.log(error);
                })
        }

    };

    loadPrograms = () => {
        let params = getParams();
        const DEFAULT_STREAM_SIZE = 40;
        let { sorting, streamType, genreName, q, onLoad, personName, tag } = this.props;
        const size = params.get("size") || DEFAULT_STREAM_SIZE;
        const page = params.get("p");
        if (isSportsLivePage()) {
            streamType = "sports";
            let day = params.get("day") || 0;
            params.set("liveStartDate", getLiveStartDateFilter(day));
            params.set("liveEndDate", getLiveEndDateFilter(day));
        }
        switch (streamType) {
            case "movies":
                streamType = 'movie';
                break;
            case 'series':
                streamType = 'tv-show';
                break;
            case 'shows':
                streamType = 'tv-show';
                params.set("types", ["shows"]);
                break;
            case 'sports':
                streamType = 'live';
                break;
            case 'documentaries':
                params.set("genre", "documentary");
                break;
            case 'free':
                params.set("free", true);
                break;
            case 'kids':
                params.set("genre", "kids");
                break;
            case 'articles':
                streamType = 'article';
                break
        }
        if (sorting) {
            params.set("sorting", sorting);
        }
        if (streamType) {
            params.set("streamType", streamType);
        }
        if (page) {
            params.set("page", page)
        }
        if (q) {
            params.set("q", q);
        }
        if (genreName) {
            params.set("genre", genreName);
        }
        if (personName) {
            params.set("person", personName);
        }
        if (tag) {
            params.set("tag", tag);
        }
        params.set("active", true);
        params.set("size", size);
        if (streamType !== 'article') {
            params.set("excludeProgramsType", ["article"]);
            let providers = getSelectedProviders();
            if (_.size(providers) > 0) {
                params.set("providers", _.join(_.map(providers, "name"), ","))
            }
        }
        params.set("seriesProgram", false);
        params.set("country", getSelectedCountry());

        http
            .get("/api/es/programs?" + params.toString())
            .then((response) => {
                let { programs, totalElements } = response.data.content;
                let pageCount = Math.ceil(totalElements / DEFAULT_STREAM_SIZE);
                this.setState({ programs, totalElements, pageCount });
                if (onLoad) {
                    onLoad({ programs: programs, totalElements: totalElements })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    getFavouriteByProgramId = (programId) => {
        const { favourites } = this.state;
        return findFavouriteByProgramId(favourites, programId);
    };

    getReminderByProgramId = (programId) => {
        const { reminders } = this.state;
        return findReminderByProgramId(reminders, programId);
    };

    onPageChange = (data) => {
        let selectedPage = data.selected;
        let params = getParams();
        params.set("p", selectedPage);
        window.location.href = window.location.pathname + "?" + params.toString();
    };

    componentDidMount() {
        this.loadFavourites();
        this.loadReminders();
        this.loadPrograms();
    }

    render() {
        let { programs, selectedPage, pageCount } = this.state;
        let screenWidth = window.screen.width;
        let chunkSize = 4;
        if (isMobileDevice()) {
            chunkSize = 2;
        }
        return (<div>
            {programs.length > 0 && pageCount > 1 ? <ReactPaginate
                previousLabel={'Tidigare'}
                nextLabel={'Nästa'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={parseInt(pageCount)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={this.onPageChange}
                containerClassName={'pagination'}
                initialPage={parseInt(selectedPage)}
                disableInitialCallback={true}
                activeClassName={'active'}

            /> : null}
            {
                _.chunk(programs, chunkSize).map((programsSubList, parentIndex) => {
                    return <div key={parentIndex}>
                        <div className="stream-listing">
                            {
                                programsSubList.map((program, index) => {
                                    return (<StreamCard program={program}
                                        reminder={this.getReminderByProgramId(program.id)}
                                        favourite={this.getFavouriteByProgramId(program.id)} />
                                    );
                                })
                            }
                        </div>
                        {/*Display second ads */}
                        {parentIndex === 1 || _.size(programs) <= chunkSize * (parentIndex + 1) ?
                            <div className="blog-ads">
                                {isDesktop() ? <div id="cncpt-lb2"></div> : ''}
                                {isTableDevice() ? <div id="cncpt-tab_lb2"></div> : ''}
                                {isMobileDevice() ? <div id="cncpt-mob2"></div> : ''}
                            </div> : null}

                        {/*Display thrid ads */}
                        {parentIndex === 3 || _.size(programs) <= chunkSize * (parentIndex + 1) ?
                            <div className="blog-ads">
                                {isDesktop() ? <div id="cncpt-lb3"></div> : ''}
                                {isTableDevice() ? <div id="cncpt-tab_lb3"></div> : ''}
                                {isMobileDevice() ? <div id="cncpt-mob3" className="desktopNone"></div> : ''}
                            </div> : null}
                    </div>
                })
            }
          
            {programs.length > 0 && pageCount > 1 ? <ReactPaginate
                previousLabel={'Tidigare'}
                nextLabel={'Nästa'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={parseInt(pageCount)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={this.onPageChange}
                containerClassName={'pagination'}
                initialPage={parseInt(selectedPage)}
                disableInitialCallback={true}
                activeClassName={'active'}

            /> : null}
        </div>
        );
    }
}

export default Streams;
