import React, {useEffect, useRef, useState} from "react";
import "../blog/blogListing.scss";
import {
    findOrGetDefaultTranslation,
    getProgramImage,
    getProgramTitle,
    getProviderLabel,
    getSelectedCountry,
    getStreamStartTime,
    isLatestTvShow,
    isLive,
    isLoggedIn,
    isProgramExpired,
    isSport,
    prettyTime,
} from "../../utils/Util";
import "./streamDetail.scss";
import _ from "lodash";
import moment from 'moment'
import 'moment/locale/sv'
import SeasonProgramsAccordion from "./SeasonProgramsAccordion";
import wiki from '../../images/wiki.jpeg'
import http from "../../services/HttpService";
import store from "../store/index";
import {toggleLoginModal} from "../action/loginModalAction";
import {getStreamDetailUrl, redirectTo} from "../../utils/UrlUtil";

const ProgramDetailTemplate = (props) => {
    const {program, favourites, onPlay, openTellToFriend} = props;
    const [favourite, setFavourite] = useState(props.favourite || {});
    const [reminder, setReminder] = useState(props.reminder || {});
    const [openLoggedOutUserPopup, setOpenLoggedOutUserPopup] = useState(false);
    const [openWatchLoggedOutUserPopup, setOpenWatchLoggedOutUserPopup] = useState(false);
    const Ref = useRef(null);
    const [timer, setTimer] = useState({});
    const translation = findOrGetDefaultTranslation(program, getSelectedCountry());

    const getTimeRemaining = () => {
        if (program && program.liveStartDate) {
            let liveStartDate = moment(program.liveStartDate);
            let now = moment();
            let days = liveStartDate.diff(now, "days");
            const total = liveStartDate.valueOf() - now.valueOf();
            const seconds = Math.floor((total / 1000) % 60);
            const minutes = Math.floor((total / 1000 / 60) % 60);
            const hours = Math.floor((total / 1000 / 60 / 60) % 24);
            return {
                total, days, hours, minutes, seconds
            };
        }
        return {}
    };


    const startTimer = () => {
        if (Ref.current) {
            clearInterval(Ref.current)
        }
        Ref.current = setInterval(() => {
            let {total, days, hours, minutes, seconds} = getTimeRemaining();
            if (total >= 0) {
                let timer = {
                    total: total,
                    days: days > 9 ? days : '0' + days,
                    hours: hours > 9 ? hours : '0' + hours,
                    minutes: minutes > 9 ? minutes : '0' + minutes,
                    seconds: seconds > 9 ? seconds : '0' + seconds,

                };
                setTimer(timer)
            } else {
                setTimer({});
                clearInterval(Ref.current)
            }
        }, 1000);
    };

    const openLogin = () => {
        store.dispatch(toggleLoginModal(true));
    };

    const saveFavourite = () => {
        if (program) {
            favourite.programId = program.id;
            http
                .post("/api/es/favourites/streamly", favourite).then((response) => {
                let {favourite} = response.data.content;
                setFavourite(favourite || {});
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    const watch = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (isLoggedIn()) {
            favourite.watch = !favourite.watch;
            saveFavourite();
        } else {
            setOpenWatchLoggedOutUserPopup(true)
        }
    };

    const like = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (isLoggedIn()) {
            favourite.like = !favourite.like;
            if (favourite.like) {
                favourite.dislike = false
            }
            saveFavourite();
        } else {
            setOpenLoggedOutUserPopup(true)
        }
    };

    const dislike = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (isLoggedIn()) {
            favourite.dislike = !favourite.dislike;
            if (favourite.dislike) {
                favourite.like = false
            }
            saveFavourite();
        } else {
            setOpenLoggedOutUserPopup(true)
        }
    };

    const getPersonPrettyList = (persons) => {
        let list = [];
        let size = _.size(persons);
        if (size === 0) {
            return list;
        }
        _.forEach(persons, (person, index) => {
            list.push(<a href={"/person/" + person}>{person}</a>);
            if (index < size - 1)
                list.push(", ")
        });
        return list;
    };

    const getTagPrettyList = (tags) => {
        let list = [];
        let size = _.size(tags);
        if (size === 0) {
            return list;
        }
        _.forEach(tags, (tag, index) => {
            list.push(<a className="tagType" href={"/tag/" + tag}>{tag}</a>);
            if (index < size - 1)
                list.push(", ")
        });
        return list;
    };

    const toggleReminder = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (isLoggedIn()) {
            let {program = {}} = props;
            http
                .get("/api/streamly/reminders/toggle/" + program.id)
                .then((response) => {
                    let {reminder} = response.data.content;
                    setReminder(reminder);
                })
                .catch((error) => {

                    console.log(error);
                })
        } else {
            this.openLogin();
        }

    };

    useEffect(() => {
        startTimer()
    }, []);

    return (
        <>
        <div className="title">
            <div className={`${program.videoUrl ? 'show-play' : ''} title-view`}>
                <a href={program.sourceReference} onClick={(e) => {
                    e.preventDefault();
                    if (program.videoUrl) {
                        onPlay();
                    }
                }} title={translation.title} target={"_blank"}>

                    <div className="shade-overlay">
                        <img src={program.imageUrl} alt={translation.title}/>
                        <div className="sportsDetails">

                            {isSport(program) ? <div className={`live-overlay`}>
                                {isLive(program) ? <span class="live-text">Live</span> : null}
                                <span className="time-text">{getStreamStartTime(program, false)}</span>
                            </div> : null}
                            <div className="percent-bar">
                                <div className="bar" style={{width: "0"}}></div>
                            </div>
                            {timer.total > 0 ? <div className="live-countdown">
                                <div className="inner">
                                    <span className="intro">Sändningen börjar om:</span> <br/>
                                    <div className="times">
                                        <div className="time-part">
                                            <div className="value days">{timer.days}</div>
                                            <div className="text">dagar</div>
                                        </div>
                                        <div className="time-part">
                                            <div className="value">:</div>
                                        </div>
                                        <div className="time-part">
                                            <div className="value hours">{timer.hours}</div>
                                            <div className="text">timmar</div>
                                        </div>
                                        <div className="time-part">
                                            <div className="value">:</div>
                                        </div>
                                        <div className="time-part">
                                            <div className="value minutes">{timer.minutes}</div>
                                            <div className="text">minuter</div>
                                        </div>
                                        <div className="time-part">
                                            <div className="value">:</div>
                                        </div>
                                        <div className="time-part">
                                            <div className="value seconds">{timer.seconds}</div>
                                            <div className="text">sekunder</div>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}


                            <div className="text-overlay">
                                <h3>{getProgramTitle(program)}</h3>
                                {/*<p>Internationella klubblagsmatcher</p>*/}
                            </div>
                        </div>
                    </div>

                    {isLive(program) ?
                        <span className="live-badge">Live</span> : null}
                    {isLatestTvShow(program) ? <span className="live-badge">Ny säsong</span> : null}
                    {isSport(program) && !isProgramExpired(program) ?
                        <button class={`remind-button small-card-remind-button ${_.size(reminder) ? 'active' : ''}`}
                                onClick={toggleReminder}
                                title="Påminn mig 10 min innan start"></button> : null}

                </a>


                <div className="thumbs-vote">
                    <span onClick={like} className={`${favourite.like ? 'checked' : ''} thumbs-up`}></span>
                    <span onClick={dislike} className={`${favourite.dislike ? 'checked' : ''} thumbs-down`}></span>
                    <div className={`popover-context-menu right ${openLoggedOutUserPopup ? 'active' : ''}`}>
                          <span className="close-btn" onClick={(e) => {
                              setOpenLoggedOutUserPopup(false)
                          }}/>
                        <div className="popover-container">
                            <div className="feedback-text">
                                <span className="non-logged-in-icon"></span>
                                {/* <img src="/Static/img/icons/checked-ring-gray.svg" class="feedback-icon" alt=""> */}
                                <p className="feedback-text">Du måste vara inloggad för att kunna rösta</p>
                                <button class="feedback-login-button login-button" onClick={openLogin}>Logga in
                                </button>
                                &nbsp;|&nbsp;
                                <a href="/User/Create" class="feedback-register-button register-button">Skapa
                                    konto</a>
                            </div>
                        </div>
                    </div>
                </div>

                {false && _.size(program.casts) > 0 ? <div className="cast-details">
                        <h1>Medverkande</h1>
                        <ul className="cast-list">
                            {
                                program.casts.map((cast, index) => {
                                    return <li key={index}>
                                        <div className="image">
                                            {/*{cast.imageUrl ? <img src={cast.imageUrl} alt={cast.name}/> : <img*/}
                                            {/*    src={defaultUserImage}/>}*/}
                                            {/*<img src={defaultUserImage}/>}*/}
                    </div>
                    <span className="name">{cast.name}</span>
                    </li>
                })
                }
            </ul>
        </div>
        : ''}
        </div>


    <div className="title-info">
        <h1>
            {getProgramTitle(program)}{" "}
            {program.year ? <span>({program.year})</span> : null}
        </h1>
        <p>{translation.description}</p>

        {program.providers ? (
            <h2 className="icons">
                {_.filter(program.providers, ["country", getSelectedCountry()]).map((provider) => {
                    return (
                        <a
                            href={provider.detailUrl}
                            title={getProviderLabel(provider.provider)}
                            target={"_blank"}
                            className="icon"
                        >
                            <span className="tag">Spela på {getProviderLabel(provider.provider)}</span>
                            <span className={`${provider.provider}_full_icon`}> </span>
                        </a>
                    );
                })}
            </h2>
        ) : null}
        <ul>

            {program.directors ? (
                <li className="tags">
                    <span className="label">Regissör</span>
                    <span>{getPersonPrettyList(program.directors)}</span>
                </li>
            ) : null}

            {program.actors ? (
                <li className="tags">
                    <span className="label">Medverkande</span>
                    <span>{getPersonPrettyList(program.actors)}</span>
                </li>
            ) : null}

            {program.languages ? (
                <li className="tags">
                    <span className="label">språk</span>
                    <span>{_.join(program.languages, ", ")}</span>
                </li>
            ) : null}
            {program.duration ? (
                <li className="tags">
                    <span className="label">Längd</span>
                    <span>{prettyTime(program.duration)}</span>
                </li>
            ) : null}

            {_.size(program.genres) > 0 ? (
                <li className="tags">
                    <span className="label">Genre:</span>
                    <span>{_.join(_.map(program.genres, "name"), ", ")}</span>
                </li>
            ) : null}

            {program.year ? (
                <li className="tags">
                    <span className="label">År:</span>
                    <span>{program.year}</span>
                </li>
            ) : null}


            {program.rating ? (
                <li className="tags">
                    <span className="label">Betyg:</span>
                    <span>{program.rating}</span>
                </li>
            ) : null}

            {program.age > 0 ? (
                <li className="tags">
                    <span className="label">Ålder:</span>
                    <span>{program.age}</span>
                </li>
            ) : null}
            {program.season ? (
                <li className="tags">
                    <span className="label">Säsong:</span>
                    <span>{program.season.number}</span>
                </li>
            ) : null}

            {program.episodeNumber ? (
                <li className="tags">
                    <span className="label">Episod:</span>
                    <span>{program.episodeNumber}</span>
                </li>
            ) : null}
            {program.countryOfOrigin ? (
                <li className="tags">
                    <span className="label">Produktionsland:</span>
                    <span>{program.countryOfOrigin}</span>
                </li>
            ) : null}
            {program.tags ? (
                <li className="tags">
                    <span className="label">Taggar:</span>
                    <span>
                                {getTagPrettyList(program.tags)}
                            </span>
                </li>
            ) : null}
            {program.programType ? (
                <li className="tags">
                    <span className="label">typ:</span>
                    <span>{program.programType === 'live' ? 'Sport' : program.programType}</span>
                </li>
            ) : null}

            {program.liveStartDate ? (
                <li className="tags">
                    <span className="label">Start:</span>
                    <span>{moment(program.liveStartDate).format("DD-MMM, YYYY HH:mm ")}</span>
                </li>
            ) : null}
        </ul>
        <div className="title-actions">
            <button onClick={(e) => {
                watch(e)
            }}
                    className={`favourite-button button large-favourite-button  ${favourite.watch ? 'favourite-white' : 'unfavourite-white'}`}
            />
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${getStreamDetailUrl(program, true)}`}
               target={"_blank"} className="button share-button button-medium facebook-button"></a>
            <a href={`https://twitter.com/share?hashtags=streamly&url=${getStreamDetailUrl(program, true)}`}
               target={"_blank"} className="button share-button button-medium twitter-button"></a>
            <a href="#" onClick={(e) => {
                e.preventDefault();
                openTellToFriend()
            }} className="button share-button button-medium email-button tip-a-friend-button">Tipsa
                en vän</a>
            <div class={`popover-context-menu left ${openWatchLoggedOutUserPopup ? 'active' : ''}`}>
                        <span className="close-btn" onClick={(e) => {
                            setOpenWatchLoggedOutUserPopup(false)
                        }}/>
                <div className="popover-container">
                    <div className="feedback-text">
                        <span className="non-logged-in-icon"></span>
                        <p className="feedback-text">Du måste vara inloggad för att kunna rösta</p>
                        <button class="feedback-login-button login-button" onClick={openLogin}>Logga in
                        </button>
                        &nbsp;|&nbsp;
                        <a href="/User/Create" class="feedback-register-button register-button">Skapa
                            konto</a>
                    </div>
                </div>
            </div>

        </div>
    </div>


</div>
    <div className="sessons-section">
        {
            program.season ? <SeasonProgramsAccordion season={program.season} favourites={favourites}/> : null
        }

        {
            _.orderBy(program.seasons, "number").map((season, index) => {
                return <SeasonProgramsAccordion season={season}/>
            })
        }
    </div>

{
    program.videoUrl || program.wikiUrl ? <div className="related-material">
        <h1>Relaterat material</h1>
        <div className="related-info">
            {program.videoUrl ? <div className="trailers">
                <div className="left">
                    <img src={getProgramImage(program)} onClick={(e) => {
                        if (program.videoUrl) {
                            onPlay();
                        }
                    }} width={100}/>
                </div>
                <div className="right">
                    <span>Trailer</span>
                    <span>Youtube</span>
                </div>
            </div> : null}
            {
                program.wikiUrl ? <div className="wiki">
                    <div className="left">
                        <img src={wiki} width={100}/>
                    </div>
                    <div className="right" onClick={() => redirectTo(program.wikiUrl)}>
                        <span>Wikipedia</span>
                        <span>Wikipedia</span>
                    </div>
                </div> : null
            }
        </div>
    </div> : null
}
</>
)
    ;

};
export default ProgramDetailTemplate;
