import React from "react";
import "./filterBar.scss";
import "../../constants/constants";
import http from "../../services/HttpService";
import _ from 'lodash';
import {getParams} from "../../utils/UrlUtil";
import {convertToQueryString} from "../../utils/Util";

class StreamGenre extends React.Component {
    constructor() {
        super();
        let params = getParams();
        this.state = {
            genres: [],
            isOpen: false
        };
    }

    toggleMenu = () => {
        this.setState({isOpen: !this.state.isOpen})
    };

    loadGenres = () => {

        http
            .get("/api/es/genres/active")
            .then((response) => {
                let {genres} = response.data.content;
                this.setState({genres});
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
            });
    };

    selectGenre = (e) => {
        let params = getParams();
        let {selectedGenre} = this.props;
        params.delete("p");
        if ("Genre" != e.target.value) {
            if (selectedGenre) {
                window.location.href = window.location.pathname.replace(selectedGenre, e.target.value) + convertToQueryString(params)
            } else {
                window.location.href = window.location.pathname + "/" + e.target.value + convertToQueryString(params)
            }
        } else {
            window.location.href = window.location.pathname.replace("/" + selectedGenre, '') + convertToQueryString(params)
        }
    };

    componentDidMount() {
        this.loadGenres();
    }

    render() {
        let {genres, isOpen} = this.state;
        let {selectedGenre} = this.props;
        return (
            <>
            <button class={`menu-button select-source-menu-button ${isOpen ? 'extended' : ''}`}
                    onClick={this.toggleMenu}>
                {selectedGenre || 'Genre'}
            </button>

            <div className={`select-source select-drop-down`}
                 style={{'display': isOpen ? 'block' : 'none'}}>
                <div className="even-columns">
                    {
                        _.orderBy(genres, "name").map((genre) => {
                            return <>
                            <input type="checkbox"
                                   checked={genre.name === selectedGenre ? 'checked' : ''}
                                   onClick={this.selectGenre} className="genre-checkbox" id={genre.name}
                                   value={genre.name}/>
                            <label for={genre.name}>{genre.label}</label>
                            </>
                        })
                    }
                </div>
                <button onClick={this.refreshPage}
                        className="button white-background button-thin"
                        id="select-source-button">Spara
                </button>
            </div>
            </>
        );
    }
}

export default StreamGenre;
