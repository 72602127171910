import React from "react";
import '../MainContent.scss';
import '../common.scss';
import {getProgramImage, getProgramTitle, getProviderLabel, isLoggedIn} from "../../utils/Util";
import http from "../../services/HttpService";
import {getStreamDetailUrl, toQueryString} from "../../utils/UrlUtil";
import _ from 'lodash'
import './MyFavouriteStream.scss';

class MyFavouriteStreamPrograms extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            favourites: [],
            isInProgress: false
        }
    }

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    loadFavouritePrograms = () => {
        if (isLoggedIn()) {
            http.get("/api/es/favourites/streamly/me" + toQueryString({withProgram: true}))
                .then((response) => {
                    this.setState({
                        favourites: response.data.content.favourites
                    });
                }).catch((error) => {
                console.log(error.toJSON)
            })
        }
    };

    removeFavourite = (programId) => {
        if (isLoggedIn() && programId) {
            http.delete("/api/es/favourites/me/" + programId)
                .then((response) => {
                    this.loadFavouritePrograms();
                }).catch((error) => {
                console.log(error.toJSON)
            })
        }
    };

    componentDidMount() {
        this.loadFavouritePrograms();
    }

    render() {
        let {favourites} = this.state;
        favourites = _.filter(favourites, ["watch", true]);
        return <div class="content-block">
            <h1>Favoriter</h1>
            <table class="playlist-table user-playlist-single">
                <thead>
                <tr>
                    <th class="playlist-title">Namn</th>
                    <th class="playlist-runtime">Längd</th>
                    <th class="playlist-source">Källa</th>
                    <th class="playlist-buttons"></th>
                </tr>
                </thead>
                <tbody>


                {
                    favourites.map((favourite, index) => {
                        return <tr key={index}>
                            <td class="playlist-title">
                                <a href={getStreamDetailUrl(favourite.program)} class="click-link">
                                    <img
                                        src={getProgramImage(favourite.program)}
                                        class="production"
                                        data-fallback="/Static/img/fallback-images/card-95x70.jpg"/>
                                    <div class="playlist-title-content">
                                        <span class="head-title">{getProgramTitle(favourite.program)}</span><br/>
                                        <span class="sub-title"></span>
                                    </div>
                                </a>
                            </td>
                            <td class="playlist-runtime">
                                {favourite.program.duration}
                            </td>
                            <td class="playlist-source">
                                {
                                    favourite.program.providers.map((provider) => {
                                        return <span>{getProviderLabel(provider.provider)}&nbsp;</span>
                                    })
                                }
                            </td>
                            <td class="playlist-buttons">
                                <button class="favourite-button button large-favourite-button favourite-white"
                                        title="Favorit"
                                        onClick={() => this.removeFavourite(favourite.programId)}></button>
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    }
}

export default MyFavouriteStreamPrograms;