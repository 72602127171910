import React, {useEffect, useState} from "react";
import {getParams} from "../../../utils/UrlUtil";
import http from "../../../services/HttpService";
import "../streamListing.scss";
import _ from 'lodash'
import {getSelectedCountry, setProviderAndRedirectTo} from "../../../utils/Util";
import StreamCard from "../StreamCard";


const StreamHomeBlock = (props) => {
    const [programs, setPrograms] = useState([]);
    const [favourites, setFavourites] = useState([]);
    const size = props.size || 8;
    const {streamType, providers, containerClass} = props;

    const loadFavourites = () => {
        http
            .get("/api/es/favourites/streamly/me")
            .then((response) => {
                let {favourites} = response.data.content;
                setFavourites(favourites || []);
            })
            .catch((error) => {
                console.log(error);
            })
    };

    const viewAll = () => {
        setProviderAndRedirectTo(providers[0], '/genre/provider')
    };

    const loadPrograms = () => {
        let params = getParams();
        if (_.size(providers) > 0) {
            params.set("providers", _.map(providers, "name"));
        }

        if (streamType === 'Program') {
            params.set("streamType", 'tv-show');
            params.set("types", ["shows"]);
        } else if (streamType) {
            params.set("streamType", streamType);
        }
        // params.set("popular", true);
        params.set("sorting", "popular");
        params.set("active", true);
        params.set("size", size);
        params.set("excludeProgramsType", ["article", "episode"]);
        params.set("country", getSelectedCountry());
        params.set("seriesProgram", false);

        http
            .get("/api/es/programs?" + params.toString())
            .then((response) => {
                let {programs} = response.data.content;
                setPrograms(programs);
            })
            .catch((error) => {
                console.log(error);
            })
    };

    const getFavouriteByProgramId = (programId) => {
        if (_.size(favourites) > 0) {
            return _.find(favourites, ["programId", programId])
        }
    };

    useEffect(() => {
        for (let i = 1; i <= 40; i++) {
            programs.push({category: {}})
        }
        setPrograms(programs);
        loadFavourites();
        loadPrograms();
    }, []);

    if (_.size(programs) === 0) {
        return <div/>
    }

    return (
        <div className={containerClass}>
            <h2 className="headingWithBorder">
                <span onClick={(e) => {
                    e.preventDefault()
                    viewAll();
                }} className={`provider-icon ${providers[0].name}_full`}></span> Populärt
                <a href={`/${getSelectedCountry().toLowerCase()}/genre/${providers[0].name}`} onClick={(e) => {
                    e.preventDefault()
                    viewAll();
                }} className="view-all-btn">Visa alla </a>
            </h2>
            {
                _.chunk(programs, _.size(programs)).map((programsSubList, parentIndex) => {
                    return <div key={parentIndex}>
                        <div
                            className={`${parentIndex === 0 ? "first-section" : ""} program-list slider-parent homePage-listing`}>
                            {
                                programsSubList.map((program, index) => {
                                    return (
                                        <div>

                                            <StreamCard program={program}
                                                        favourite={getFavouriteByProgramId(program.id)}/>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                })
            }
        </div>
    );
};

export default StreamHomeBlock;
