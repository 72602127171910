import React from 'react';
import '../ProgramsLists.scss'
import http from "../../services/HttpService";
import Accordion from 'react-bootstrap/Accordion';
import {Button} from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import SeasonPrograms from "./SeasonPrograms";

class SeasonProgramsAccordion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            programs: [],
            isOpen: false
        }
    }

    findAllProgramsBySeason = (season) => {
        if (season) {
            http
                .get("/api/es/programs/findAllBySeason/" + season.friendlyUrl)
                .then((response) => {
                    let {programs} = response.data.content;
                    this.setState({programs})
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    toggleAccordion = () => {
        let {isOpen} = this.state;
        this.setState({isOpen: !isOpen});
    };

    componentDidMount() {
        let {season} = this.props;
        this.findAllProgramsBySeason(season)
    }

    UNSAFE_componentWillMount() {
    }

    render() {
        let {season = {}, favourites = []} = this.props;
        let {isOpen} = this.state;
        return <Accordion>
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" onClick={this.toggleAccordion}
                                      className={`${isOpen ? 'extended' : ''}`}
                                      eventKey={season.id}>
                        Säsong {season.number}
                        <span class="indicator">{isOpen ? 'Dölj avsnitt' : 'Visa avsnitt'}</span>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={season.id}>
                    <Card.Body>
                        <SeasonPrograms season={season} favourites={favourites}/>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    }
}

export default SeasonProgramsAccordion