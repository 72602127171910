import React from 'react';
import './header/Header.scss'
import {
    getSelectedCountry,
    isArticleListPage,
    isFilmPage,
    isSeriesPage,
    isTopListPage,
    setSelectedProviders
} from "../utils/Util";
import {getUrlWithCountry, redirectToUrl} from "../utils/UrlUtil";
import {getTranslatedText} from "../utils/Translation";

class TopNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLogin: false,
            selectedCountry: getSelectedCountry()
        }
    }

    clearAndRedirect = (url) => {
        setSelectedProviders([]);
        redirectToUrl(url);
    }

    render() {
        let {selectedCountry} = this.state;
        return (
            <div>
                <nav className="navbar">

                    <a href={getUrlWithCountry("/genre/movies")}
                       className={`${isFilmPage() ? 'selected' : ''}`}
                       onClick={(e) => {
                           e.preventDefault()
                           this.clearAndRedirect(getUrlWithCountry("/genre/movies"))
                       }}>{getTranslatedText(selectedCountry, "nav.film")}</a>
                    <a href={getUrlWithCountry("/genre/series")} title="Streamly - Serier"
                       className={`${isSeriesPage() ? 'selected' : ''}`}
                       onClick={(e) => {
                        e.preventDefault()
                        this.clearAndRedirect(getUrlWithCountry("/genre/series"))
                    }}>{getTranslatedText(selectedCountry, "nav.series")}</a>
                    {/* <a href={getUrlWithCountry("/genre/shows")} title="Streamly - Program" onClick={(e) => {
                        e.preventDefault()
                        this.clearAndRedirect(getUrlWithCountry("/genre/shows"))
                    }}>{getTranslatedText(selectedCountry, "nav.program")}</a> */}
                    {/* <a href={getUrlWithCountry("/sports")} title="Streamly - Sports" onClick={(e) => {
                        e.preventDefault()
                        this.clearAndRedirect(getUrlWithCountry("/sports"))
                    }}>{getTranslatedText(selectedCountry, "nav.sport")}</a> */}
                    {/* <a href={getUrlWithCountry("/genre/free")} onClick={(e) => {
                        e.preventDefault()
                        this.clearAndRedirect(getUrlWithCountry("/genre/free"))
                    }}>{getTranslatedText(selectedCountry, "nav.free")}</a> */}
                    {/* <a href={getUrlWithCountry("/genre/documentaries")} onClick={(e) => {
                        e.preventDefault()
                        this.clearAndRedirect(getUrlWithCountry("/genre/documentaries"))
                    }}>{getTranslatedText(selectedCountry, "nav.documentary")}</a> */}
                    <a href={getUrlWithCountry("/genre/topplistor")}
                       className={`${isTopListPage() ? 'selected' : ''}`}
                       onClick={(e) => {
                        e.preventDefault()
                        this.clearAndRedirect(getUrlWithCountry("/genre/topplistor"))
                    }}>{getTranslatedText(selectedCountry, "nav.toplist")}</a>
                    <a href={getUrlWithCountry("/genre/articles")} title="Streamly - Artiklar"
                       className={`${isArticleListPage() ? 'selected' : ''}`}
                       onClick={(e) => {
                        e.preventDefault()
                        this.clearAndRedirect(getUrlWithCountry("/genre/articles"))
                    }}>{getTranslatedText(selectedCountry, "nav.article")}</a>
                    {/* <a href={getUrlWithCountry("/page/play")} title="Streamly - Play" onClick={(e) => {
                        e.preventDefault()
                        this.clearAndRedirect(getUrlWithCountry("/page/play"))
                    }}>Play</a> */}
                </nav>

            </div>
        )
    }
}

export default TopNavigation