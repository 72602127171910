import React, {useEffect} from "react";
import "../blog/blogListing.scss";
import "./streamDetail.scss";
import 'moment/locale/sv'

const ArticleTemplate = (props) => {
    const {program, openTellToFriend} = props;

    useEffect(() => {
    }, []);

    return (
        <>
            <div>
                <img src={program.imageUrl} width="100%"/>
            </div>
            <div className="title">
                <h1>
                    {program.title}{" "}
                    {program.year ? <span>({program.year})</span> : null}
                </h1>
                <div>
                    <p dangerouslySetInnerHTML={{__html: program.description}}/>
                </div>
                <div className="title-info">
                    <div className="title-actions">
                        <a href="#" className="button share-button button-medium facebook-button"></a>
                        <a href="#" className="button share-button button-medium twitter-button"></a>
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            openTellToFriend()
                        }} className="button share-button button-medium email-button tip-a-friend-button">Tipsa
                            en vän</a>
                        <a href="#" className="error-report-link"
                           title="Rapportera att något är fel med innehåller eller att uppspelningslänken är trasig">Rapportera
                            fel</a>
                    </div>
                </div>
            </div>
        </>
    );

};
export default ArticleTemplate;
