import React from "react";

class Play extends React.Component {
  constructor(props) {
    super(props);
    this.state = { content: undefined };
  }

  // componentDidMount() {
  //     let params = {contentType: this.props.type};
  //     http
  //         .get("/api/contents" + toQueryString(params))
  //         .then((response) => {
  //             let {pages} = response.data.content;
  //             let page = pages[0] || {};
  //             this.setState({content: page.content});
  //         })
  //         .catch((error) => {
  //             console.log(error.toJSON())
  //         })
  // }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-12 static-content streamly-play">
            <div class="wrap">
              <section class="container">
                <h1>
                  Vi tar en paus med Streamly Play och återkommer om ett par
                  veckor med nya bra filmtitlar. Med Streamly Play kan man se
                  filmer kostnadsfritt och lagligt som medlem.
                </h1>
                <a
                  href="/User/Create"
                  class="element-button streamly-play__button-register"
                >
                  BLI MEDLEM GRATIS
                </a>
              </section>

              <div class="ad-block"></div>
              <section class="container">
                <p> </p>
                <h3>Enkelt. Gratis. Lagligt.</h3>
                <p></p>
                <p>
                  I samarbete med SF Anytime får våra medlemmar se en ny och
                  handplockad storfilm under en helg.
                </p>

                <p>
                  Filmen är tillgänglig i 48 timmar och visas i full HD med
                  Chromecast-stöd.
                </p>
              </section>
              <div class="ad-block"></div>
              <div class="ad-block"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Play;
