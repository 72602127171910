import React, {useEffect, useState} from "react";
import {getParams, getStreamDetailUrl, redirectToUrl} from "../../../utils/UrlUtil";
import {getProgramImage, getProgramTitle, getProviderLabel, getSelectedCountry} from "../../../utils/Util";
import http from "../../../services/HttpService";
import ReactPaginate from 'react-paginate';
import './ProgramsNews.scss';
import _ from "lodash";

const ProgramsNews = (props) => {
    let params = getParams();
    const [programs, setPrograms] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const size = 32;
    let page = params.get("p") || 0;
    const loadPrograms = () => {
        let params = getParams();
        params.set("active", true);
        params.set("sort", "recentlyAdded");
        params.set("order", "desc");
        params.set("size", size);
        params.set("country", getSelectedCountry());
        params.set("excludeProgramsType", ["article"]);
        params.set("sorting", "prioritized");

        if (page) {
            params.set("page", page)
        }
        http
            .get("/api/es/programs?" + params.toString())
            .then((response) => {
                let {programs, totalElements} = response.data.content;
                setPrograms(programs);
                setTotalElements(totalElements);
                let pageCount = Math.ceil(totalElements / size);
                setPageCount(pageCount)
            })
            .catch((error) => {
                console.log(error);
            })
    };

    const onPageChange = (data) => {
        let selectedPage = data.selected;
        let params = getParams();
        params.set("p", selectedPage);
        window.location.href = window.location.pathname + "?" + params.toString();
    };


    useEffect(() => {
        setPrograms(programs);
        loadPrograms();
    }, []);

    return (<div>
            {programs.length > 0 && pageCount > 1 ? <ReactPaginate
                previousLabel={'Tidigare'}
                nextLabel={'Nästa'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={parseInt(pageCount)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={onPageChange}
                containerClassName={'pagination'}
                initialPage={parseInt(page)}
                disableInitialCallback={true}
                activeClassName={'active'}

            /> : null}
            <div className="news-list">
                <ul className="user-feed">
                    {
                        programs.map((program, index) => {
                            return <li className="news-item" key={index}>
                                <a href={getStreamDetailUrl(program)} onClick={(e) => {
                                    e.preventDefault();
                                    redirectToUrl(getStreamDetailUrl(program))
                                }}>
                                    <div class="news-thumb">
                                        <img src={getProgramImage(program)} width={100} alt={program.title}/>
                                    </div>
                                    <div className={"news-description"}>
                                        <p>
                                            Ny {program.programType} '{getProgramTitle(program)}'
                                            hos {_.filter(program.providers, ["country", getSelectedCountry()])
                                            .map((provider) => {
                                                return <span>{getProviderLabel(provider.provider)}&nbsp;</span>
                                            })}
                                        </p>
                                        {
                                            _.filter(program.providers, ["country", getSelectedCountry()]).map((provider) => {
                                                return <span className={`${provider.provider}_full_icon`}> </span>

                                            })
                                        }
                                    </div>
                                </a>
                            </li>
                        })
                    }
                </ul>
            </div>

            {programs.length > 0 && pageCount > 1 ? <ReactPaginate
                previousLabel={'Tidigare'}
                nextLabel={'Nästa'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={parseInt(pageCount)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={onPageChange}
                containerClassName={'pagination'}
                initialPage={parseInt(page)}
                disableInitialCallback={true}
                activeClassName={'active'}

            /> : null}
        </div>
    );
};

export default ProgramsNews;
