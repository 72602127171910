import React from 'react';
import '../ProgramsLists.scss'
import http from "../../services/HttpService";
import _ from 'lodash'
import StreamCard from "./StreamCard";
import {findFavouriteByProgramId} from "../../utils/Util";

class SeasonPrograms extends React.Component {

    constructor(props) {
        super(props);
        this.state = {programs: []}
    }

    findAllProgramsBySeason = (season) => {
        if (season) {
            http
                .get("/api/es/programs/findAllBySeason/" + season.friendlyUrl)
                .then((response) => {
                    let {programs} = response.data.content;
                    this.setState({programs})
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    componentDidMount() {
        let {season} = this.props;
        this.findAllProgramsBySeason(season)
    }

    UNSAFE_componentWillMount() {
    }

    render() {
        let {programs} = this.state;
        let {favourites} = this.props;
        return <div className="slider-parent homePage-listing program-list">
            
            {
                _.orderBy(programs, "episodeNumber").map((program) => {
                    return (
                       
 <StreamCard hideUserActions={true} program={program}
                                    favourite={findFavouriteByProgramId(favourites, program.id)}/>);
                        
                       
                })
            }
           
        </div>
    }
}

export default SeasonPrograms