import React from "react";
import "./filterBar.scss";
import "../../constants/constants";
import _ from 'lodash';
import {redirectTo} from "../../utils/UrlUtil";
import {
    getSelectedCountry,
    getSelectedProviders,
    isAllPage,
    isProviderDetailPage,
    isStreamHomePage,
    isStreamListPage,
    isTopListPage,
    setSelectedProviders
} from "../../utils/Util";

class ProvidersList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            providers: [],
            selectedProviders: getSelectedProviders(),
        };
    }

    loadProviders = () => {
        fetch("https://www.kolla.tv/api/es/providers/active/" + getSelectedCountry())
            .then((res) => res.json())
            .then((response) => {
                let {providers} = response.content;
                this.setState({providers});
            })

    };

    isProviderSelected = (provider) => {
        if (isStreamHomePage()) {
            return true;
        }
        if (provider) {
            let {selectedProviders} = this.state;
            return _.some(selectedProviders, ["name", provider.name]);
        }
    };

    isAnyProviderSelected = () => {
        let {selectedProviders} = this.state;
        return _.size(selectedProviders) > 0
    };

    toggleProvider = (provider) => {

        // let {selectedProviders} = this.state;
        // if (_.some(selectedProviders, ["name", provider.name])) {
        //     _.remove(selectedProviders, {name: provider.name})
        // } else {
        //     selectedProviders.push(provider)
        // }
        // let providers = selectedProviders.map((provider) => {
        //     return {
        //         name: provider.name,
        //         label: provider.label
        //     }
        // });
        // setSelectedProviders(providers);

        let {selectedProviders} = this.state;
        if (_.some(selectedProviders, ["name", provider.name])) {
            setSelectedProviders([]);
        } else {
            setSelectedProviders([{
                name: provider.name,
                label: provider.label
            }]);

        }
        setSelectedProviders([{
            name: provider.name,
            label: provider.label
        }]);
        // window.location.href = '/genre/all';
        redirectTo('/genre/provider');


        // if (isStreamHomePage() || isAllPage()) {
        //     redirectTo('/genre/provider');
        // } else if (isTopListPage()) {
        //     redirectTo('/genre/all');
        // } else {
        //     reloadPage();
        // }
    };

    componentDidMount() {
        this.loadProviders();
    }

    render() {
        let {providers} = this.state;
        providers = _.orderBy(providers, "tabIndex");
        let country = getSelectedCountry().toLowerCase();
        if (!(isProviderDetailPage() || isTopListPage() || isStreamListPage() || isStreamHomePage() || isAllPage())) {
            return <div/>
        }
        return (
            <div className={`${this.isAnyProviderSelected() ? 'provider-selected' : ''} provider-section`}>
                <div className="providerList sticky-top container">
                    <div className="scroll-nav">
                        <ul className={`${this.isAnyProviderSelected() ? 'provider-selected' : ''} nav`}>
                            <li>
                                <a href={`/${country}/genre/all`} onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedProviders([]);
                                    redirectTo(`/genre/all`);
                                }} className="choose-btn" type="button">Visa alla leverantörer</a>
                            </li>
                            {
                                providers.map((provider, index) => {
                                    return <li key={index}
                                               className={`${this.isProviderSelected(provider) ? 'selected' : ''}`}>
                                        <a className={`nav-link`}
                                           title={provider.label}
                                           onClick={(e) => {
                                               e.preventDefault();
                                               this.toggleProvider(provider)
                                           }}
                                           href={window.location.pathname}>
                                            <span className={`${provider.name}_full`}> </span>
                                        </a>
                                    </li>
                                })
                            }
                        </ul>
                        {/*<button className="btn btn-primary" onClick={this.clearSelectedProviders} type="button">Clear*/}
                        {/*Filter*/}
                        {/*</button>*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default ProvidersList;
