import {CLOSE_LOGIN_MODAL, OPEN_LOGIN_MODAL} from "../action/loginModalAction";

const initialState = {
    isOpen: false
};
const loginModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_LOGIN_MODAL:
            return {...state, isOpen: true};
        case CLOSE_LOGIN_MODAL:
            return {...state, isOpen: false};
        default:
            return state;
    }
};
export default loginModalReducer;