import _ from 'lodash'
import {getSelectedCountry} from "./Util";

export const getParams = (key) => {
    if (key) {
        return new URLSearchParams(window.location.search).get(key);
    }
    return new URLSearchParams(window.location.search);
};

export const toQueryString = (json) => {
    return '?' + new URLSearchParams(json).toString()
};

export const redirectTo = (url, target = '_self') => {
    let country = getSelectedCountry().toLowerCase();
    window.open("/" + country + url, target);
};


export const redirectToUrl = (url, target = '_self') => {
    window.open(url, target);
};

export const getUrlWithCountry = (uri) => {
    let country = getSelectedCountry().toLowerCase();
    return "/" + country + uri;
};

export const getStreamDetailUrl = (program, absolute) => {
    let host = '';
    let selectedCountry = getSelectedCountry();
    if (absolute) {
        host = `https://${window.location.host}`
    }
    if (program && program.id) {
        let url;
        if (program.programType === 'article') {
            url = `${host}/${selectedCountry.toLowerCase()}/artikel`
        } else {
            url = `${host}/${selectedCountry.toLowerCase()}/title`
            if (_.size(program.providers) > 0) {
                url = url + `/${_.join(_.map(_.uniqBy(program.providers, "provider"), ((provider) => {
                    return provider.provider + '-' + getSelectedCountry().toLowerCase()
                })), "-")}`
            }
            if (program.season) {
                if (program.season.streamlyFriendlyUrl) {
                    url = url + `/${program.season.streamlyFriendlyUrl}`
                } else {
                    url = url + `/${program.season.friendlyUrl}`
                }
            }
        }

        if (program.streamlyFriendlyUrl) {
            url = url + `/${program.streamlyFriendlyUrl}`
        } else {
            url = url + `/${program.friendlyUrl}`
        }
        return url;
    }
};