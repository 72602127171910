import React from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import '../Form.scss'
import http from '../../services/HttpService'
import loader from '../../images/loader.gif'
import SimpleReactValidator from 'simple-react-validator';
import {loginViaGoogle, postLoginRegister} from "../../utils/Util";
import ToastUtil from "../../utils/ToastUtil";
import {connect} from "react-redux";
import {toggleLoginModal} from "../action/loginModalAction";
import store from '../store/index'

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: undefined,
            password: undefined,
            isInProgress: false
        };

        this.validator = new SimpleReactValidator({
            element: (message) => {
                return <span className="error">{message}</span>
            }
        });
    }

    onUsernameChange = (event) => {
        this.setState({username: event.target.value})
    };

    onPasswordChange = (event) => {
        this.setState({password: event.target.value})
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    closeModal = () => {
        store.dispatch(toggleLoginModal(false));
    };

    doLogin = (event) => {
        event.preventDefault();
        if (this.validator.allValid() && !this.state.isInProgress) {
            this.toggleInProgress(true);
            http.post("/api/login", {
                username: this.state.username,
                password: this.state.password
            }).then(response => {
                let data = response.data;
                localStorage.setItem("user", JSON.stringify(data.content.user));
                localStorage.setItem("jtoken", data.content.token);
                postLoginRegister();
            }).catch(error => {
                ToastUtil.error(error.response.data.errors[0]);
            }).finally(() => {
                this.toggleInProgress(false);
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    render() {
        let {isInProgress} = this.state;
        let {loginModal = {}} = this.props;
        return (
            <Modal
                show={loginModal.isOpen}
                onHide={this.closeModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span className="streamly_icon"></span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.doLogin}>
                        <Form.Group controlId="username">
                            <Form.Label>E-postadress</Form.Label>
                            <Form.Control type="email" name="username" onChange={this.onUsernameChange}/>
                            {this.validator.message('email', this.state.username, 'required|email')}
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>Lösenord</Form.Label>
                            <Form.Control type="password" name="password" onChange={this.onPasswordChange}/>
                            {this.validator.message('password', this.state.password, 'required')}
                        </Form.Group>
                        <Form.Group controlId="rememberMe">
                            <Form.Check type="checkbox" label="Kom ihåg mig"/>
                        </Form.Group>
                        <Button variant="primary" type="submit" className="cyan-background">Logga in</Button>
                        <Form.Group controlId="register">
                            <a href="/User/Create" target="_self">Skapa Konto</a>
                        </Form.Group>
                        <Form.Group controlId="password">
                            <a href="/User/Password/Reset" target={"_self"}>Glömt lösenord?</a>
                        </Form.Group>
                        <a href="" onClick={(e) => {
                            e.preventDefault();
                            loginViaGoogle()
                        }} class="button social-media-button button-medium google-button">
                            Google+
                        </a>

                        {isInProgress ? <img src={loader}/> : ''}
                    </Form>

                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {loginModal: state.loginModal}
};

export default connect(mapStateToProps)(Login)