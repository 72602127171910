import React, {useEffect, useState} from "react";
import http from "../../services/HttpService";
import "../blog/blogListing.scss";
import {format} from "../../utils/DateUtil";
import {Helmet} from "react-helmet";
import {
    getAdsLabel,
    getHeaderDesktopAds,
    getProgramImage,
    getSelectedCountry,
    getTopDesktopAds,
    isSport,
} from "../../utils/Util";
import {isDesktop, isMobileDevice, isTableDevice,} from "../../utils/DeviceUtil";
import "./streamDetail.scss";
import _ from "lodash";
import StreamCarousel from "./StreamCarousel";
import StreamPlay from "./StreamPlay";
import 'moment/locale/sv'
import ProgramDetailTemplate from "./ProgramDetailTemplate";
import ArticleTemplate from "./ArticleTemplate";
import ProgramSearchTemplate from "./ProgramSearchTemplate";
import TellToFriend from "./TellToFriend";
import {getStreamDetailUrl} from "../../utils/UrlUtil";

const ProgramDetail = (props) => {
    const [program, setProgram] = useState({});
    const [programNotFound, setProgramNotFound] = useState(false);
    const [favourite, setFavourite] = useState({});
    const [reminder, setReminder] = useState({});
    const [favourites, setFavourites] = useState([]);
    const [showPlay, setShowPlay] = useState(false);
    const [openTellToFriend, setOpenTellToFriend] = useState(false);

    const onPlay = () => {
        setShowPlay(true)
    };

    const closePlay = () => {
        setShowPlay(false);
    };

    const doOpenTellToFriend = () => {
        setOpenTellToFriend(true);
    };

    const doCloseTellToFriend = () => {
        setOpenTellToFriend(false);
    };

    const setTitleAndMetadata = (program) => {
        if (program) {
            return (
                <Helmet>
                    <title>{"Streamly | " + program.title}</title>
                    <meta name="description" content={program.description}/>
                </Helmet>
            );
        }
    };

    const getStructuredData = (program) => {
        let data = {};
        if (program) {
            data = {
                "@context": "https://schema.org",
                "@type": "BlogPosting",
                mainEntityOfPage: {
                    "@type": "WebPage",
                    "@id": `https://www.streamly.com${getStreamDetailUrl(program)}`,
                },
                headline: `${program.title}`,
                description: `${program.description}`,
                image: `${program.imageUrl}`,
                author: {
                    "@type": "Person",
                    name: `${"Streamly"}`,
                },
                publisher: {
                    "@type": "Organization",
                    name: "streamly.com",
                    logo: {
                        "@type": "ImageObject",
                        url: getProgramImage(program),
                    },
                },
                datePublished: `${format(program.dateCreated, "YYYY-MM-DD")}`,
            };
        }
        return JSON.stringify(data);
    };

    const setStructureData = (program) => {
        if (program && program.id) {
            return (
                <Helmet>
                    <script type="application/ld+json">
                        {getStructuredData(program)}
                    </script>
                </Helmet>
            );
        }
    };

    const setFacebookOpenGraphData = (program) => {
        if (program && program.id) {
            return (
                <Helmet>
                    <meta property="og:title" content={`${program.title} | Streamly`}/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content={`https://www.streamly.com${getStreamDetailUrl(program)}`}/>
                    <meta property="og:locale" content="sv_SE"/>
                    <meta property="og:site_name" content="Streamly"/>
                    <meta property="og:description"
                          content={`${program.title}`}/>
                    <meta property="og:image"
                          content={`${program.imageUrl}`}/>
                    <meta property="fb:app_id" content="458135101036793"/>
                </Helmet>
            );
        }
    };

    const loadFavourites = () => {
        http
            .get("/api/es/favourites/streamly/me")
            .then((response) => {
                let {favourites} = response.data.content;
                setFavourites(favourites);
            })
            .catch((error) => {
                console.log(error);
            })
    };

    const isArticle = (program) => {
        if (program) {
            return program.programType === 'article';
        }
        return false;
    };

    const findByFriendlyUrl = async () => {
        const {
            match: {params},
        } = props;
        let friendlyUrl = params.friendlyUrl;
        try {
            let programResponse = await http.get("/api/es/programs/findByFriendlyUrl/" + friendlyUrl);
            let {program} = programResponse.data.content;
            if (_.size(program) > 0) {
                let favouriteResponse = await http.get("/api/es/favourites/streamly/me/" + program.id);
                let {favourite} = favouriteResponse.data.content;
                setFavourite(favourite || {});
                let reminderResponse = await http.get("/api/streamly/reminders/me/" + program.id);
                let {reminder} = reminderResponse.data.content;
                setReminder(reminder || {});
            }
            setProgram(program);
            setProgramNotFound(!(program && program.id))
        } catch (e) {
            setProgramNotFound(true);
        }
    };

    useEffect(() => {
        loadFavourites();
        findByFriendlyUrl();
    }, []);

    if (program && program.id) {
        return (
            <div className="content-block program-details">
                {setTitleAndMetadata(program)}
                {setStructureData(program)}
                {setFacebookOpenGraphData(program)}

                <div className="blog-ads">
                    {getTopDesktopAds()}
                    {getHeaderDesktopAds()}
                    {isTableDevice() ? <>
                        <div id="cncpt-tab_lb1"></div>
                    </> : ""}
                </div>

                {
                    isArticle(program) ? <ArticleTemplate program={program} openTellToFriend={doOpenTellToFriend}/> :
                        <ProgramDetailTemplate openTellToFriend={doOpenTellToFriend} program={program}
                                               favourite={favourite} favourites={favourites} reminder={reminder}
                                               onPlay={onPlay}/>
                }

                <div className="blog-ads">
                    {getAdsLabel()}
                    {isDesktop() ? <div id="cncpt-lb2"></div> : ""}
                    {isTableDevice() ? <div id="cncpt-tab_lb2"></div> : ""}
                    {isMobileDevice() ? <div id="cncpt-mob2"></div> : ""}
                </div>

                <h1>Rekommenderat</h1>
                {program.providers ? (
                    <StreamCarousel isSportPage={isSport(program)}
                                    isArticlePage={isArticle(program)}
                                    favourites={favourites}
                                    heading={" "}
                                    country={getSelectedCountry()}
                                    providers={_.map(program.providers, "provider")}
                                    genres={_.map(program.genres, "name")}
                    />
                ) : null}

                <div className="blog-ads">
                    {getAdsLabel()}
                    {isDesktop() ? <div id="cncpt-lb3"></div> : ""}
                    {isTableDevice() ? <div id="cncpt-tab_lb3"></div> : ""}
                    {isMobileDevice() ? <div id="cncpt-mob3"></div> : ""}
                </div>

                <h1>Från samma kategori</h1>
                {program.providers ? (
                    <StreamCarousel
                        isSportPage={isSport(program)}
                        isArticlePage={isArticle(program)}
                        favourites={favourites}
                        heading={" "}
                        country={getSelectedCountry()}
                        genres={_.map(program.genres, "name")}
                    />
                ) : null}

                {showPlay ? <StreamPlay show={showPlay} onHide={closePlay} program={program}/> : null}
                <ProgramSearchTemplate/>
                <TellToFriend show={openTellToFriend} onHide={doCloseTellToFriend} program={program}/>
            </div>
        );
    }
    if (programNotFound) {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="error-template text-center">
                        <br/>
                        <br/>
                        <br/>
                        <h1>Oops!</h1>
                        <h2>404 Ej Hittad</h2>
                        <div className="error-details">Tyvärr, begärd sida hittades inte!</div>
                        <br/>
                        <div className="error-actions">
                            <a href="/Stream" target="_self" className="btn btn-primary btn">
                                Stream
                            </a>
                        </div>
                        <br/>
                        <br/>
                        <ProgramSearchTemplate/>
                    </div>
                </div>
            </div>
        );
    }
    return <div/>
};
export default ProgramDetail;
