import React from 'react';
import {Alert, Button, Form, Row} from 'react-bootstrap';
import '../Form.scss'
import http from "../../services/HttpService";
import SimpleReactValidator from 'simple-react-validator';
import loader from '../../images/loader.gif'
import {loginViaGoogle, postLoginRegister} from "../../utils/Util";
import {RECAPTA_KEY} from "../../constants/constants";
import ReCAPTCHA from "react-google-recaptcha";
import ToastUtil from "../../utils/ToastUtil";

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payload: {
                username: '',
                firstName: '',
                lastName: '',
                password: ''
            },
            isInProgress: false,
            errors: [],
            isRobot: true
        };
        this.validator = new SimpleReactValidator({
            element: (message) => {
                return <span className="error">{message}</span>
            }
        });
    }

    changeHandler = (event) => {
        let {payload} = this.state;
        payload[event.target.name] = event.target.value;
        this.setState({payload});
    };

    onCaptchaChange = (value) => {
        console.log("Captcha value:", value);
        this.setState({isRobot: false})
    };

    onCaptchaExpired = () => {
        this.setState({isRobot: true})
    };

    doRegister = (event) => {
        event.preventDefault();
        if (this.validator.allValid() && !this.state.isInProgress) {
            let {payload, isRobot} = this.state;
            if (isRobot) {
                ToastUtil.error("Vänligen verifiera att du inte är robot.");
                return;
            }
            this.toggleInProgress(true);
            http
                .post("/api/users/register", payload)
                .then((response) => {
                    let {content, status, errors} = response.data;
                    if (status) {
                        localStorage.setItem("user", JSON.stringify(content.user));
                        localStorage.setItem("jtoken", content.token);
                        localStorage.setItem("isNewUser", true);
                        postLoginRegister("/");
                    } else {
                        this.setState({errors: errors})
                    }
                })
                .catch(() => {
                })
                .finally(() => {
                    this.toggleInProgress(false)
                })

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    render() {

        let {payload} = this.state;
        return (
            <div className="container">

                <div className="row">
                    <div className="col-sm-8 register-form">
                        <h2>Gå med i Streamly</h2>
                        <p>Med ett användarkonto på Streamly får du tillgång till Streamly Play där vi varje vecka visar
                            en ny och handplockad
                            storfilm gratis, möjligheten att skapa egna spellistor, skräddarsydda film- och serietips,
                            följa dina vänners rekommendationer, ställa in personliga profiler för anpassat innehåll och
                            mycket mer.</p>
                        <p>Att skapa ett konto är gratis.</p>
                        <div className="two-column">


                            <div class="social-column">
                                <h2>Google+</h2>
                                {/*<a href="/Login/AuthorizeFacebook"*/}
                                {/*class="button social-media-button button-medium facebook-button">Logga in med*/}
                                {/*Facebook</a>*/}
                                <a href="" onClick={(e) => {
                                    e.preventDefault();
                                    loginViaGoogle()
                                }} class="button social-media-button button-medium google-button">
                                    Logga in med Google+
                                </a>
                            </div>
                            <div className="form">
                                <h2>Användarnamn och lösenord</h2>
                                <Form className="form" onSubmit={this.doRegister} method="POST">
                                    <Row>
                                        <Form.Label>Förnamn</Form.Label>
                                        <Form.Control name="firstName" onChange={this.changeHandler}/>
                                        {this.validator.message('firstName', payload.firstName, 'required')}
                                    </Row>
                                    <Row>
                                        <Form.Label>Efternamn</Form.Label>
                                        <Form.Control name="lastName" onChange={this.changeHandler}/>
                                        {this.validator.message('lastName', payload.lastName, 'required')}
                                    </Row>
                                    <Row>
                                        <Form.Label>E-postadress</Form.Label>
                                        <Form.Control name="username" type="email" onChange={this.changeHandler}/>
                                        {this.validator.message('email', payload.username, 'required|email')}
                                    </Row>
                                    <Row>
                                        <Form.Label>Lösenord</Form.Label>
                                        <Form.Control name="password" type="password" onChange={this.changeHandler}/>
                                        {this.validator.message('password', payload.password, 'required')}
                                    </Row>
                                    <Row>
                                        <ReCAPTCHA
                                            theme={'dark'}
                                            sitekey={RECAPTA_KEY}
                                            onChange={this.onCaptchaChange}
                                            onExpired={this.onCaptchaExpired}
                                        />
                                    </Row>
                                    {
                                        this.state.errors.map((error, index) => {
                                            return <Alert key={index} variant={'danger'}>
                                                {error}
                                            </Alert>
                                        })
                                    }
                                    <Button variant="primary" type="submit" className="cyan-background">
                                        Skapa konto
                                        &nbsp;{this.state.isInProgress ?
                                        <img src={loader}/> : ''}
                                    </Button>
                                </Form>
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        )
    }
}

export default Register