import React, {useEffect, useState} from "react";
import Streams from "./Streams";
import "../streamListing.scss";
import "../../ProgramsLists.scss";

import {getParams, getStreamDetailUrl, redirectToUrl} from "../../../utils/UrlUtil";
import {
    convertToQueryString,
    findOrGetDefaultTranslation, getAdsLabel,
    getHeaderDesktopAds,
    getSelectedCountry,
    getSelectedProviderLabel,
    getSelectedProviderName,
    getSelectedProviders,
    getSortingLabel,
    getStreamTypeHeading,
    getTopDesktopAds,
    getTopMobileAds,
    isAllPage,
    isAnyProviderSelected,
    isArticleListPage,
    isFilmOrSeriesPage,
    isProviderDetailPage,
    isSearchPage,
    isSportsLivePage,
    isSportsPage,
} from "../../../utils/Util";
import {isDesktop, isMobileDevice, isTableDevice,} from "../../../utils/DeviceUtil";
import Providers from "../Providers";
import StreamGenre from "../StreamGenre";
import {Helmet} from "react-helmet";
import SportsDays from "../SportsDays";
import StreamSliderBlock from "./StreamSliderBlock";
import _ from "lodash";
import http from "../../../services/HttpService";
import StreamPlay from "../StreamPlay";
import {getTranslatedText} from "../../../utils/Translation";
import StreamBlock from "./StreamBlock";

const StreamList = (props) => {
    let [totalElements, setTotalElements] = useState(0);
    let [bannerProgram, setBannerProgram] = useState({});
    let [translation, setTranslation] = useState({});
    let [play, setPlay] = useState(false);
    let [providers, setProviders] = useState([]);
    let params = getParams();
    let selectedSorting = params.get("sorting");
    let selectedCountry = getSelectedCountry();
    let isProviderDetail = isProviderDetailPage();
    if (!selectedSorting) {
        if (isArticleListPage() || isSportsPage()) {
            selectedSorting = "latest";
        } else if (isSportsLivePage()) {
            selectedSorting = "start";
        }
    }
    if (!selectedSorting) {
        selectedSorting = "newRelease";
    }
    let {streamType, genreName, q, personName, tag} = props;

    const playTrailer = () => {
        setPlay(true)
    };

    const closeTrailer = () => {
        setPlay(false)
    };

    const selectSorting = (value) => {
        let params = getParams();
        params.set("sorting", value);
        window.location.href =
            window.location.pathname + convertToQueryString(params);
    };

    const onProgramsLoad = ({totalElements}) => {
        setTotalElements(totalElements);
    };

    const setTitleAndMetadata = () => {
        let heading = getStreamTypeHeading(streamType);
        if (!heading) {
            heading = tag;
        }
        if (!heading) {
            heading = personName;
        }
        if (!heading) {
            heading = genreName;
        }
        if (!heading) {
            heading = q;
        }
        let title = heading + " | Streamly";
        let description =
            "Streamly är tjänsten för dig som enkelt vill kunna hänga med i streamingutbudet. Du ska alltid kunna hitta något som passar just dig, just nu. Välj eller ta bort kategorier så det passar dig, fördjupa dig i intervjuer, eller följ dina vänners rekommendationer.";

        return (
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
            </Helmet>
        );
    };

    const canShowProvidersAndGenre = () => {
        return !(personName || tag || isArticleListPage());
    };

    const isAnyProviderSelectedOnAllPage = () => {
        return isAnyProviderSelected() && isAllPage();
    };

    const loadPrograms = () => {
        let params = getParams();
        params.set("sorting", "latest");
        params.set("newRelease", true)
        params.set("active", true);
        params.set("size", 10);
        params.set("excludeProgramsType", ["article"]);
        let providers = getSelectedProviders();
        if (_.size(providers) > 0) {
            params.set("providers", _.join(_.map(providers, "name"), ","))
        }

        params.set("seriesProgram", false);
        params.set("country", selectedCountry);
        http
            .get("/api/es/programs?" + params.toString())
            .then((response) => {
                let {programs} = response.data.content;
                if (_.size(programs) > 0) {
                    let program = _.sample(programs)
                    setBannerProgram(program);
                    setTranslation(findOrGetDefaultTranslation(program, selectedCountry));
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }


    const loadProviders = () => {
        let response = http
            .get("/api/es/providers/active/" + getSelectedCountry())
            .then((response) => {
                let {providers} = response.data.content;
                // this.setState({providers: _.orderBy(providers, "tabIndex")})
                setProviders(_.orderBy(providers, "tabIndex"))
            })

    }

    const getStreamSection = () => {
        if (isProviderDetail) {
            return ProviderDetailBlocks
        } else if (!isAnyProviderSelected() && isFilmOrSeriesPage()) {
            return ProvidersBlock
        } else {
            return StreamList
        }
    }

    const getPageHeader = () => {
        if (!(isProviderDetail || !isAnyProviderSelected() && isFilmOrSeriesPage())) {
            if (isSearchPage()) {
                return SearchPageHeader
            } else {
                return AllPageHeader
            }
        }
    }

    useEffect(() => {
        loadPrograms();
        loadProviders();
    }, []);

    const SortingOptions = (
        <div className="sorting">
            {isSportsLivePage() ? (
                <span className={`${selectedSorting === "start" ? "active" : ""}`}>
          <a href={`/${window.location.pathname}?sorting=start`}
             onClick={(e) => {
                 e.preventDefault();
                 selectSorting("start");
             }}>
            {getSortingLabel("start")}
          </a>
        </span>
            ) : null}
            {!(isArticleListPage() || isSportsLivePage()) ? (
                <span
                    className={`${selectedSorting === "newRelease" ? "active" : ""}`}
                >
          <a href={`/${window.location.pathname}?sorting=newRelease`}
             onClick={(e) => {
                 e.preventDefault();
                 selectSorting("newRelease");
             }}>
            {getTranslatedText(selectedCountry, "new.release")}
          </a>
        </span>
            ) : null}
            {!(isArticleListPage() || isSportsLivePage()) ? (
                <span className={`${selectedSorting === "topList" ? "active" : ""}`}>
          <a
              href={`/${window.location.pathname}?sorting=topList`}
              onClick={(e) => {
                  e.preventDefault();
                  selectSorting("topList");
              }}
          >
            {getTranslatedText(selectedCountry, "top.list")}
          </a>
        </span>
            ) : null}
            {!(isArticleListPage() || isSportsLivePage()) ? (
                <span className={`${selectedSorting === "trending" ? "active" : ""}`}>
          <a href={`/${window.location.pathname}?sorting=trending`}
             onClick={(e) => {
                 e.preventDefault();
                 selectSorting("trending");
             }}>
            {" "}
              {getTranslatedText(selectedCountry, "trending")}
          </a>
        </span>
            ) : null}
        </div>
    );

    const SearchPageHeader = (
        <div>
            <div className="stream-heading">
                <h1>Sökresultat</h1>
            </div>
            <p>{`Din sökning på "${q}" gav ${totalElements} sökträffar.`}</p>
        </div>
    );

    const AllPageHeader = (
        <div>
            <div className="stream-heading">
                <h1 className="with-sub">
                    {`${getStreamTypeHeading(streamType)}`}
                    {_.size(getSelectedProviders()) > 0 ? (
                        <sub class="nav">
                            Utvalda leverantörer{" "}
                            {_.join(_.map(getSelectedProviders(), "label"), ", ")}
                        </sub>
                    ) : null}
                    {isSportsLivePage() ? (
                        <sub class="nav">
                            <a href={`/${getSelectedCountry().toLowerCase()}/genre/sports`}>
                                Se övrig sport
                            </a>
                        </sub>
                    ) : null}
                    {isSportsPage() ? (
                        <sub class="nav">
                            <a href={`/${selectedCountry.toLowerCase()}/sports`}>
                                Se livesändningar
                            </a>
                        </sub>
                    ) : null}
                </h1>
            </div>
        </div>
    );

    const StreamList = (<>
            <div className="topSection block-filter">
                {!isAnyProviderSelectedOnAllPage() ? (
                    SortingOptions
                ) : (
                    <div className="sorting"/>
                )}
                {canShowProvidersAndGenre() ? (
                    <div className="rightSection">
                        {isSportsLivePage() ? <SportsDays/> : null}
                        <Providers/>
                        <StreamGenre selectedGenre={genreName}/>
                    </div>
                ) : null}
            </div>
            <Streams
                onLoad={onProgramsLoad}
                q={q}
                sorting={selectedSorting}
                streamType={streamType}
                genreName={genreName}
                personName={personName}
                tag={tag}
            />
        </>
    );

    const ProvidersBlock = <>
        <div className="stream-listing-page">
            {getTopDesktopAds()}
            {getTopMobileAds()}
            {
                providers.map((provider, index) => {
                    return <div>
                        {provider && provider.name ?
                            <StreamBlock size={8} containerClass={`listingPage`}
                                         streamType={streamType}
                                         providers={[provider]} viewAllUrl={"/genre/movies"}/> : null}

                        {index === 0 ? <div className="blog-ads">
                            {getAdsLabel()}
                            {isDesktop() ? <div id="cncpt-lb2"></div> : ''}
                            {isTableDevice() ? <div id="cncpt-tab_lb2"></div> : ''}
                            {isMobileDevice() ? <div id="cncpt-mob2"></div> : ''}
                        </div> : null}
                        {index === 1 ? <div className="blog-ads">
                            {getAdsLabel()}
                            {isDesktop() ? <div id="cncpt-lb3"></div> : ''}
                            {isTableDevice() ? <div id="cncpt-tab_lb3"></div> : ''}
                            {isMobileDevice() ? <div id="cncpt-mob3"></div> : ''}
                        </div> : null}
                    </div>
                })
            }
        </div>

    </>

    const ProviderDetailBlocks = (
        <div className={"provider-details"}>
            {bannerProgram.id ?
                <div className="static-banner" style={{backgroundImage: `url(${bannerProgram.imageUrl})`}}>
                    <div className="banner-details">
                        <div className="topSection block-filter">
                            <div className="sorting"/>
                            <div className="rightSection">
                                {isSportsLivePage() ? <SportsDays/> : null}
                                <Providers/>
                            </div>
                        </div>
                        <span className={`provider-icon ${getSelectedProviderName()}_full`}></span>
                        <h4 className="item-name">{translation.title}</h4>
                        <p>
                            {translation.description}
                        </p>
                        <div className="flex">
                            {bannerProgram.videoUrl ?
                                <button className="play-button"
                                        onClick={playTrailer}>{getTranslatedText(selectedCountry, "play.trailer")}</button> : null}
                            <button className="info-button" onClick={() => {
                                redirectToUrl(getStreamDetailUrl(bannerProgram, false))
                            }}>{getTranslatedText(selectedCountry, "more.info")}
                            </button>
                        </div>

                    </div>
                </div> : null}

            <StreamSliderBlock
                sorting={"topList"}
                heading={`${getTranslatedText(selectedCountry, "top.ten.series")} ${getSelectedProviderLabel()}`}
                streamType={"series"}
                containerClass="top-10-series"
                viewAllUrl={`/${selectedCountry.toLowerCase()}/genre/series?sorting=topList`}
                size={10}
            />
            <div className="blog-ads">
                {getAdsLabel()}
                {isDesktop() ? <div id="cncpt-lb2"></div> : ""}
                {isTableDevice() ? <div id="cncpt-tab_lb2"></div> : ""}
                {isMobileDevice() ? <div id="cncpt-mob2"></div> : ""}
            </div>
            <StreamSliderBlock
                sorting={"latest"}
                heading={`${getTranslatedText(selectedCountry, "new.release")} ${getSelectedProviderLabel()}`}
                newRelease={true}
                viewAllUrl={`/${selectedCountry.toLowerCase()}/genre/all?sorting=newRelease`}
                size={10}
            />

            <StreamSliderBlock
                sorting={"topList"}
                heading={`${getTranslatedText(selectedCountry, "top.ten.film")} ${getSelectedProviderLabel()}`}
                streamType={"movies"}
                containerClass="top-10-movies"
                viewAllUrl={`/${selectedCountry.toLowerCase()}/genre/movies?sorting=topList`}
                size={10}
            />

            <div className="blog-ads">
                {getAdsLabel()}
                {isDesktop() ? <div id="cncpt-lb3"></div> : ""}
                {isTableDevice() ? <div id="cncpt-tab_lb3"></div> : ""}
                {isMobileDevice() ? <div id="cncpt-mob3"></div> : ""}
            </div>

            <StreamSliderBlock
                sorting={"popular"}
                heading={`${getTranslatedText(selectedCountry, "popular.now")} ${getSelectedProviderLabel()}`}
                viewAllUrl={`/${selectedCountry.toLowerCase()}/genre/all?sorting=popular`}
                size={10}
            />
            {bannerProgram ? <StreamPlay show={play} onHide={closeTrailer} program={bannerProgram}/> : null}
        </div>
    );

    return (
        <div className="stream-listing-page for-all-provider">
            {/*<ProvidersList/>*/}
            {setTitleAndMetadata()}
            {getTopDesktopAds()}
            {getTopMobileAds()}

            {getHeaderDesktopAds()}
            {/*{getHeaderMobileAds()}*/}
            {isTableDevice() ? <>
                <div id="cncpt-tab_lb1"></div>
            </> : ""}
            {getPageHeader()}
            {getStreamSection()}
        </div>
    );
};

export default StreamList;
