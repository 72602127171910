import React from 'react';
import {toQueryString} from "../../utils/UrlUtil";
import http from "../../services/HttpService";

class PageContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {content: undefined}
    }

    componentDidMount() {
        let params = {contentType: this.props.type};
        http
            .get("/api/contents" + toQueryString(params))
            .then((response) => {
                let {pages} = response.data.content;
                let page = pages[0] || {};
                this.setState({content: page.content});
            })
            .catch((error) => {
                console.log(error.toJSON())
            })
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-lg-12 static-content">
                        <div dangerouslySetInnerHTML={{__html: this.state.content}}/>
                    </div>

                </div>
            </div>
        )
    }
}

export default PageContent