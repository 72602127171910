import {
    DEFAULT_COUNTRY_CODE,
    DEFAULT_SIZE,
    SELECTED_COUNTRY_CODE_KEY,
    TABLE_LAYOUT,
    TIMELINE_LAYOUT,
    TOTAL_WEEK_DAYS
} from "../constants/constants";
import _ from 'lodash'
import {getParams, redirectTo, toQueryString} from "./UrlUtil";
import http from "../services/HttpService";
import {TIME_ZONE, toDuration} from "./DateUtil";
import 'moment/locale/sv'
import moment from 'moment-timezone'
import {Pagination} from 'react-bootstrap';
import {isMobile, isTablet} from "react-device-detect";
import {isMobileDevice} from "./DeviceUtil";
import store from "../components/store/index";
import {toggleLoginModal} from "../components/action/loginModalAction";
import {toggleContactUsModal} from "../components/action/contactUsModalAction";
import {API} from "../constants/api";

export const MY_PROGRAMS = 'myPrograms';
export const JTOKEN = 'jtoken';
export const FIRST_VISIT_INFO = 'firstVisitInfo';
export const SELECTED_PROVIDERS = 'streamlySelectedProviders';
let location = window.location;

export const getLayoutWithDefaultValue = () => {
    return isTimelineLayoutSelected() ? TIMELINE_LAYOUT : TABLE_LAYOUT;
};

export const addMyPrograms = (program, channel) => {
    let programs = getAllMyPrograms();
    if (_.findIndex(programs, {id: program.id}) < 0) {
        programs.push({
            ...program,
            channel: {id: channel.id, sourceId: channel.sourceId, name: channel.name}
        });
        localStorage.setItem(MY_PROGRAMS, JSON.stringify(programs));
    }
};

export const bulkAddToMyPrograms = (success, error, always) => {
    let programIds = _.map(getAllMyPrograms(), "id");
    let params = {programIds: programIds};
    if (_.size(programIds) > 0) {
        return http.get("/api/programs/bulkAddToMyPrograms" + toQueryString(params))
            .then(success)
            .catch(error)
            .finally(() => {
                if (always) {
                    always(true)
                }
            })
    } else {
        if (always) {
            always(false)
        }
    }
};

export const clearAllMyPrograms = () => {
    localStorage.setItem(MY_PROGRAMS, JSON.stringify([]));
};

export const getMyPrograms = () => {
    let programs = getAllMyPrograms();
    let day = getDay();
    let startDate = moment.tz(TIME_ZONE).add(day, "days").set("hour", 6).set("minute", 0).set("second", 0).set("millisecond", 0).toDate();
    let endDate = moment().add(day + 1, "days").set("hour", 6).set("minute", 0).set("second", 0).set("millisecond", 0).toDate();
    return programs.filter((program) => {
        let programStartTime = new Date(program.startTime);
        return startDate.getTime() <= programStartTime.getTime() && programStartTime.getTime() <= endDate.getTime()
    });
};

export const getAllMyPrograms = () => {
    return JSON.parse(localStorage.getItem(MY_PROGRAMS)) || [];
};

export const removeMyPrograms = (program) => {
    let programs = getAllMyPrograms();
    _.remove(programs, {id: program.id});
    localStorage.setItem(MY_PROGRAMS, JSON.stringify(programs));
};

const base64ToArrayBuffer = (base64) => {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
};

export const downloadFile = (name, base64, contentType) => {
    let bytes = base64ToArrayBuffer(base64);
    let blob = new Blob([bytes], {type: contentType});
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = name;
    link.click();
};

export const isTimelineLayoutSelected = () => {
    return location.pathname.includes("/Gridview")
};

export const getSelectedWeek = () => {
    let selectedWeek = parseInt(getParams("week"));
    if (!selectedWeek) {
        selectedWeek = 1
    }
    return selectedWeek
};

export const getSelectedDay = () => {
    let selectedDay = parseInt(getParams("day"));
    if (!selectedDay) {
        selectedDay = 0;
    }
    return selectedDay % 7;
};

export const isLoggedIn = () => {
    return !!localStorage.getItem(JTOKEN)
};

export const logout = () => {
    localStorage.removeItem(JTOKEN);
    localStorage.removeItem("user");
};

export const getTimelineDurations = (range) => {
    let durations = [];
    let day = getDay();
    let start = moment.tz(TIME_ZONE).add(day, "days");
    start.hours(6);
    start.minutes(0);
    let now = moment.tz(TIME_ZONE);
    let minutes, isFirstDateActive = day > 0, active;
    for (let i = 0; i < range; i++) {
        active = false;
        minutes = start.minute();
        if (minutes < 30) {
            start.minutes(0);
        } else {
            start.minutes(30);
        }
        if (!isFirstDateActive) {
            active = now.minute() >= start.minute() && now.hour() === start.hour()
        }
        durations.push({
            date: start.clone(),
            isActive: isFirstDateActive && i === 0 || active,
            duration: toHourMinuteDuration(start.clone())
        });
        start = start.add(30, "minutes")
    }
    return durations
};

export const toHourMinuteDuration = (momentInstance) => {
    return toDuration(momentInstance.hours()) + ":" + toDuration(momentInstance.minute());
};

export const postLoginRegister = (redirectUrl) => {
    if (redirectUrl) {
        redirectTo(redirectUrl)
    } else {
        window.location.reload(false);
    }
};

export const getDay = () => {
    return (getSelectedWeek() - 1) * TOTAL_WEEK_DAYS + getSelectedDay();
};

export const isSearchPage = () => {
    return window.location.pathname.includes("/Search/");
};

export const isFilmPage = () => {
    return location.pathname.includes("/genre/movies");
};

export const isFilmOrSeriesPage = () => {
    return isFilmPage() || isSeriesPage()
}

export const isStreamListPage = () => {
    return isFilmPage() || isSeriesPage() || isSportsPage();
}

export const isSportsPage = () => {
    return location.pathname.includes("/genre/sports");
};

export const isSportsLivePage = () => {
    if (isSportsPage()) {
        return false;
    }
    return location.pathname.includes("/sports");
};

export const isSeriesPage = () => {
    return location.pathname.includes("/genre/series");
};

export const isTopListPage = () => {
    return location.pathname.includes("/genre/topplistor");
};

export const isRegisterPage = () => {
    return location.pathname.includes('register');
};

export const isArticleListPage = () => {
    return location.pathname.includes('/genre/articles');
};

export const isArticleDetailPage = () => {
    return location.pathname.includes('/artikel');
};

export const isAllPage = () => {
    return location.pathname.includes('/genre/all');
};

export const isProviderDetailPage = () => {
    return location.pathname.includes('/genre/provider');
}

export const isStreamHomePage = () => {
    return location.pathname === '/'
        || /^\/[a-zA-Z]{2}\/$/.test(location.pathname)
        || /^\/[a-zA-Z]{2}$/.test(location.pathname);
};

export const isStreamDetail = () => {
    return location.pathname.includes('/play');
};
export const isChannelDetailPage = () => {
    return location.pathname.includes("/kanal/");
};

export const isFooterPage = () => {
    return isSitemapPage() ||
        isAdvertisementPage() ||
        isHelpPage() ||
        isAboutPage() ||
        isCookiePage()
};

export const isSitemapPage = () => {
    return location.pathname.includes("/Sitemap");
};
export const isAdvertisementPage = () => {
    return location.pathname.includes("/Annonsera");
};
export const isHelpPage = () => {
    return location.pathname.includes("/Help");
};
export const isAboutPage = () => {
    return location.pathname.includes("/page/about");
};
export const isCookiePage = () => {
    return location.pathname.includes("/page/cookies");
};

export const isAdmin = () => {
    let user = getCurrentUser();
    return user.isAdmin;
};

export const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user")) || {};
};

export const isNewUser = () => {
    return window.localStorage.getItem("isNewUser");
};

export const setFirstVisitOnce = () => {
    if (!getFirstVisit().date) {
        localStorage.setItem(FIRST_VISIT_INFO, JSON.stringify({date: new Date().getTime()}))
    }
};

export const getFirstVisit = () => {
    return JSON.parse(localStorage.getItem(FIRST_VISIT_INFO)) || {};
};

export const getChannelLogo = (channel, className) => {
    className = className || '';
    if (channel.logo) {
        return <span className={className}><img src={channel.logo} alt={channel.name}/></span>
    } else {
        return <span className={`largelogo-${channel.sourceId} ${className}`}/>
    }
};

export const removeLastChar = (str) => {
    if (str && str.length > 0) {
        str = str.trim();
        str = str.substring(0, str.length - 1);
    }
    return str;
};

export const getPagination = (totalElements, selectedPage, params) => {
    let items = [];
    let lastPage = Math.ceil(totalElements / DEFAULT_SIZE);
    for (let pageNumber = 0; pageNumber < lastPage; pageNumber++) {
        params.set("page", pageNumber);
        items.push(
            <Pagination.Item href={"?" + params.toString()} key={pageNumber} active={pageNumber == selectedPage}>
                {pageNumber + 1}
            </Pagination.Item>,
        );
    }
    return <Pagination>{items}</Pagination>;
};

export const getPageNumbers = (totalElements) => {
    let items = [];
    let lastPage = Math.ceil(totalElements / 10000);
    for (let pageNumber = 0; pageNumber < lastPage; pageNumber++) {
        items.push(pageNumber);
    }
    return items;
};

export const gotoPage = (number) => {
    let params = getParams();
    params.set("page", number);
    window.location.href = "?" + params.toString();
};

export const getTopDesktopAds = () => {
    if (!isMobile || isTablet) {
        return <>
            {getAdsLabel()}
            <div id="cncpt-dsk_top"></div>
        </>
    }
};

export const getTopMobileAds = () => {
    if (isMobileDevice()) {
        return <>
            {getAdsLabel()}
            <div id="cncpt-mob_top"></div>
        </>
    }
};

export const getHeaderDesktopAds = () => {
    if (!isMobile) {
        return <div id="adscontainer" className="top-add-container">
            {getAdsLabel()}
            <div id="cncpt-lb1_skn"></div>
        </div>
    }
};


export const getHeaderMobileAds = () => {
    if (isMobile) {
        return <div id="adscontainer" className="top-add-container mobile-header-add">
            {getAdsLabel()}
            <div className="ad-placeholder">
                <div id="cncpt-mob1"></div>
            </div>

        </div>
    }
};

export const ellipsis = (str, n) => {
    if (str && str.length > n) {
        return str.substring(0, n) + "...";
    } else {
        return str;
    }
};

export const setBlogSettings = (settings) => {
    localStorage.setItem("blogSettings", JSON.stringify(settings))
};

export const getBlogSettings = () => {
    let blogSettings = localStorage.getItem("blogSettings") || '{}';
    return JSON.parse(blogSettings);
};

export const setHasViewBlog = (blog) => {
    let viewedBlogs = getHasViewBlog();
    if (!hasViewedBlog(blog)) {
        viewedBlogs.push({'id': blog.id});
        localStorage.setItem('viewedBlogs', JSON.stringify(viewedBlogs));
    }
};

export const getHasViewBlog = (blog) => {
    return JSON.parse(localStorage.getItem("viewedBlogs") || '[]');
};

export const hasViewedBlog = (blog) => {
    let viewedBlogs = getHasViewBlog();
    return _.some(viewedBlogs, ['id', blog.id]);
};

export const setSelectedCountry = (code) => {
    localStorage.setItem(SELECTED_COUNTRY_CODE_KEY, code)
};

export const getSelectedCountry = () => {
    return localStorage.getItem(SELECTED_COUNTRY_CODE_KEY) || DEFAULT_COUNTRY_CODE
};

export const hasCountrySelected = () => {
    return _.size(localStorage.getItem(SELECTED_COUNTRY_CODE_KEY)) > 0
}

export const getProgramImage = (program) => {
    let image = _.find(program.images, {size: '600X400'});
    if (image && image.imageUrl) {
        return image.imageUrl
    }
    return program.imageUrl;
};

export const prettyTime = (minutes) => {
    if (minutes) {
        let minutesInt = parseInt(minutes);
        let hour = Math.floor(minutesInt / 60);
        let minute = minutesInt % 60;
        let prettyTimeStr = '';
        if (hour > 0) {
            prettyTimeStr = hour + "h "
        }
        return prettyTimeStr + minute + 'm'
    }
};

export const prettyBool = (bool) => {
    if (bool) {
        return 'Yes'
    }
    return 'No'
};

export const parseBoolean = (value) => {
    return /^true$/.test(value)
};

export const getProviderLabel = (providerName) => {
    let name = providerName;
    switch (providerName) {
        case 'netflix':
            name = 'Netflix';
            break;
        case 'cmore':
            name = 'C More';
            break;
        case 'primevideo':
        case 'amazonprimevideo':
            name = 'Prime Video';
            break;
        case 'hbonordic':
            name = 'HBO Nordic';
            break;
        case 'hbomax':
            name = 'HBO Max';
            break;
        case 'sfanytime':
            name = 'Sfanytime';
            break;
        case 'viaplay':
            name = 'Viaplay';
            break;
        case 'svtplay':
            name = 'SVT Play';
            break;
        case 'dplay':
            name = 'Discovery+';
            break;
        case 'hbo':
            name = 'HBO';
            break;
        case 'viafree':
            name = 'Viafree';
            break;
        case 'tv4play':
            name = 'TV4 Play';
            break;
        case 'plejmo':
            name = 'Plejmo';
            break;
        case 'appletv':
            name = 'Apple TV';
            break;
        case 'disneyplus':
            name = 'Disney+';
            break;
        case 'streamly-se':
            name = 'Streamly'
    }
    return name;
};

export const isLive = (program) => {
    if (program && program.live && program.liveStartDate && program.liveEndDate) {
        let now = new Date().getTime();
        let liveStartDate = new Date(program.liveStartDate);
        let liveEndDate = new Date(program.liveEndDate);
        return liveStartDate <= now && now <= liveEndDate
    }
    return false;
};


export const isProgramExpired = (program) => {
    if (program && program.liveStartDate) {
        let now = new Date().getTime();
        let liveStartDate = new Date(program.liveStartDate);
        return liveStartDate <= now
    }
    return false;
};

export const isLatestTvShow = (program) => {
    if (program && _.size(program.seasons) > 0) {
        return _.some(program.seasons, ["latest", true])
    }
    return false;
};

export const getStreamStartTime = (program, withDate) => {
    if (program && program.liveStartDate) {
        try {
            let startDateStr = moment(program.liveStartDate).format("YYYY-MM-DD");
            let todayStr = moment().format("YYYY-MM-DD");
            if (startDateStr === todayStr) {
                return <>{moment(program.liveStartDate).format("HH:mm")}</>
            } else if (withDate) {
                return moment(program.liveStartDate).format("DD MMM HH:mm")
            } else {
                return <>{moment(program.liveStartDate).format("HH:mm dddd")}</>
            }
        } catch (e) {
            console.log(e)
        }
    }
};

export const convertToQueryString = (params) => {
    return _.size(params.toString()) > 0 ? "?" + params.toString() : '';
};

export const openLoginModal = () => {
    store.dispatch(toggleLoginModal(true));
};

export const openContactUsModal = () => {
    store.dispatch(toggleContactUsModal(true));
};


export const doLogout = (e) => {
    if (e) {
        e.stopPropagation()
    }
    redirectTo("/logout");
};

export const setSelectedProviders = (providers) => {
    localStorage.setItem(SELECTED_PROVIDERS, JSON.stringify(providers));
};

export const getSelectedProviders = () => {
    return JSON.parse(localStorage.getItem(SELECTED_PROVIDERS)) || [];
};

export const isAnyProviderSelected = () => {
    return _.size(getSelectedProviders()) > 0;
}

export const findFavouriteByProgramId = (favourites, programId) => {
    if (_.size(favourites) > 0) {
        return _.find(favourites, ["programId", programId])
    }
};

export const findReminderByProgramId = (reminders, programId) => {
    if (_.size(reminders) > 0) {
        return _.find(reminders, ["programId", programId])
    }
};

export const loginViaGoogle = () => {
    window.location.href = `${API}/oauth2/authorization/google`
};

export const getStreamTypeHeading = (streamType) => {
    let type = streamType;
    switch (streamType) {
        case "movies":
            type = 'Film';
            break;
        case 'series':
            type = 'Serier';
            break;
        case 'shows':
            type = 'Program';
            break;
        case 'sports':
            type = 'Sport - Övrigt';
            break;
        case 'documentaries':
            type = 'Dokumentär';
            break;
        case 'free':
            type = 'Gratis';
            break;
        case 'kids':
            type = 'Barn';
            break;
        case 'articles':
            type = 'Artiklar';
            break;
        case 'all':
            type = 'Alla Film och serier';
            break
    }
    if (isSportsLivePage()) {
        type = "Sport - Live"
    }
    // if (streamType !== 'articles' && type && _.size(getSelectedProviders()) === 1) {
    //     type = `${type} på ${_.join(_.map(getSelectedProviders(), "label"), ", ")}`
    // }
    if (!type) {
        type = "Streamly"
    }
    return type;
};

export const getLiveStartDateFilter = (day) => {
    day = day || 0;
    return moment().add(day, "days").format("YYYY-MM-DDT00:00:00")
};

export const getLiveEndDateFilter = (day) => {
    day = day || 0;
    return moment().add(day, "days").format("YYYY-MM-DDT23:59:59")
};

export const isSport = (program) => {
    if (program) {
        return program.programType === 'live';
    }
    return false;
};

export const getProgramTitle = (program) => {
    if (program) {
        if (program.productionTitle) {
            return program.productionTitle
        }
        return program.title
    }
};

export const reloadPage = () => {
    window.location.reload(false);
};

export const getSortingLabel = (sortingKey) => {
    let label = '';
    switch (sortingKey) {
        case 'start':
            label = 'Tid'
            break;
        case 'prioritized':
            label = 'Nyheter'
            break;
        case 'rating':
            label = 'Betyg'
            break;
        case 'latest':
            label = 'Senaste'
            break;
        case 'title':
            label = 'A-Ö'
            break;
        case 'newRelease':
            label = 'New Release'
            break;
        case 'topList':
            label = 'Top List'
            break;
        case 'trending':
            label = 'Trending'
            break;
    }
    return label;
}

export const findOrGetDefaultTranslation = (program, selectedCountry) => {
    let translation = {
        title: getProgramTitle(program),
        description: program.description
    };
    if (program && _.size(program.translations) > 0) {
        translation = _.find(program.translations, {"country": selectedCountry}) || {}
        if (!translation.title) {
            translation.title = program.title
        }
        if (!translation.description) {
            translation.description = program.description
        }
    }
    return translation
}
export const getSelectedProviderName = () => {
    let providers = getSelectedProviders();
    if (_.size(providers) === 1) {
        return providers[0].name
    }
    return '';
}

export const getSelectedProviderLabel = () => {
    let providers = getSelectedProviders();
    if (_.size(providers) === 1) {
        return providers[0].label
    }
    return '';
}

export const isAnyProviderSelectedOnAllPage = () => {
    return isAnyProviderSelected() && isAllPage();
}

export const getSettings = (program, selectedCountry) => {
    if (program && _.size(program.settings) > 0) {
        return _.find(program.settings, {"country": selectedCountry}) || {}
    }
    return {};
}

export const setProviderAndRedirectTo = (provider, url) => {
    setSelectedProviders([{
        name: provider.name,
        label: provider.label
    }]);
    redirectTo(url);
}

export const getAdsLabel = () => {
    // return <div className="ad-label"><span>ANNONS</span></div>
}