import React from 'react'
import IsLoggedIn from "../user/IsLoggedIn";
import LoginHeader from "../userPages/LoginHeader";
import {Alert, Button, Form, Row} from 'react-bootstrap';
import loader from '../../images/loader.gif'
import http from "../../services/HttpService";
import SimpleReactValidator from 'simple-react-validator';
import ToastUtil from "../../utils/ToastUtil";
import _ from 'lodash'

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                username: '',
                firstName: '',
                lastName: '',
                password: '',
                yearOfBirth: '',
                gender: ''
            },
            isInProgress: false,
            errors: [],
        };

        this.validator = new SimpleReactValidator({
            element: (message) => {
                return <span className="error">{message}</span>
            }
        });
    }

    saveSettings = (evt) => {
        evt.preventDefault();
        let {user, isInProgress} = this.state;
        if (this.validator.allValid() && !isInProgress) {
            this.toggleInProgress(true);
            let params = {
                firstName: user.firstName,
                lastName: user.lastName,
                username: user.username,
                yearOfBirth: user.yearOfBirth,
                gender: user.gender
            };

            if (user.password) {
                if (!(user.password === user.confirmPassword)) {
                    ToastUtil.error("Lösenord och bekräfta Lösenordet stämmer inte överens");
                    return;
                }
                params.password = user.password;
            }
            http.put("/api/users/saveSettings", params)
                .then((response) => {
                    let {user} = response.data.content;
                    this.setState({
                        user: user
                    });
                    ToastUtil.success("Settings has been saved");
                })
                .catch((error) => {
                    console.log(error.toJSON)
                })
                .finally(() => {
                    this.toggleInProgress(false)
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    changeHandler = (event) => {
        let {user} = this.state;
        user[event.target.name] = event.target.value;

        this.setState({user});
    };

    toggleChangeHandler = (evt) => {
        let {user} = this.state;
        user[evt.target.name] = !user[evt.target.name];
        this.setState({user});
    };

    getYears = () => {
        let currentYear = new Date().getFullYear();
        return _.reverse(_.range(1901, currentYear, 1))
    };

    componentDidMount() {
        this.toggleInProgress(true);
        http.get("/api/users/getSettings")
            .then((response) => {
                let {user = {}} = response.data.content;
                this.setState({
                    user: user
                });
            })
            .catch((error) => {
                alert("error in fetch settings")
            })
            .finally(() => {
                this.toggleInProgress(false)
            })
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="user-container setting-page">
                            <div className="content-block content-block-thin">
                                <LoginHeader/>
                                <IsLoggedIn>
                                    <br/>
                                    <h2>Användarinställningar</h2>
                                    <Form className="form" onSubmit={this.saveSettings}
                                          method="POST">
                                        <Form.Group controlId="firstName">
                                            <Form.Label className={"font-weight-bold"}>Förnamn</Form.Label>
                                            <Form.Control name="firstName" onChange={this.changeHandler}
                                                          value={this.state.user.firstName} placeholder="Förnamn"/>
                                            {this.validator.message('firstName', this.state.user.firstName, 'required')}
                                        </Form.Group>
                                        <Form.Group controlId="lastName">
                                            <Form.Label className={"font-weight-bold"}>Efternamn</Form.Label>
                                            <Form.Control name="lastName" onChange={this.changeHandler}
                                                          value={this.state.user.lastName} placeholder="Efternamn"/>
                                            {this.validator.message('lastName', this.state.user.lastName, 'required')}
                                        </Form.Group>
                                        <Form.Group controlId="lastName">

                                            <Form.Label className={"font-weight-bold"}>E-postadress</Form.Label>
                                            <Form.Control name="username" type="email" onChange={this.changeHandler}
                                                          value={this.state.user.username}
                                                          placeholder="E-postadress"/>
                                            {this.validator.message('username', this.state.user.username, 'required|email')}
                                        </Form.Group>
                                        <Form.Group controlId="lastName">

                                            <Form.Label>Födelseår</Form.Label>
                                            <select className="form-control" name="yearOfBirth"
                                                    value={this.state.user.yearOfBirth}
                                                    onChange={this.changeHandler}>
                                                {
                                                    this.getYears().map((year) => {
                                                        return <option value={year}>{year}</option>
                                                    })
                                                }
                                            </select>
                                        </Form.Group>

                                        <Form.Group controlId="gender">
                                            <Form.Check value={"M"} name={"gender"}
                                                        type="radio"
                                                        checked={this.state.user.gender === "M"}
                                                        onChange={this.changeHandler}
                                                        id={`radio-male`}
                                                        label={`Man`}/>
                                            <Form.Check value={"F"} name={"gender"}
                                                        type="radio"
                                                        checked={this.state.user.gender === "F"}
                                                        onChange={this.changeHandler}
                                                        id={`radio-female`}
                                                        label={`Kvinna`}/>
                                        </Form.Group>
                                        <Form.Group controlId="password">
                                            <Form.Label className={"font-weight-bold"}>Nytt lösenord</Form.Label>
                                            <Form.Control name="password" type="password"
                                                          onChange={this.changeHandler}
                                                          placeholder="Lösenord"/>
                                        </Form.Group>
                                        <Form.Group controlId="confirmPassword">
                                            <Form.Label className={"font-weight-bold"}>Bekräfta
                                                lösenord</Form.Label>
                                            <Form.Control name="confirmPassword" type="password"
                                                          onChange={this.changeHandler}
                                                          placeholder="Lösenord"/>
                                        </Form.Group>
                                        {
                                            this.state.errors.map((error, index) => {
                                                return <Alert key={index} variant={'danger'}>
                                                    {error}
                                                </Alert>
                                            })
                                        }
                                        <Row>
                                            <div className="col-md-12">
                                                <Button variant="primary cyan-background" type="submit">SKICKA</Button>
                                                &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                                            </div>
                                        </Row>
                                    </Form>

                                </IsLoggedIn>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Settings
