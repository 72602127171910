import React from "react";
import "./MainContent.scss";
import StreamHome from "./program/home/StreamHome";
import {
    getSelectedProviderName,
    isAnyProviderSelectedOnAllPage,
    isProviderDetailPage,
    isSearchPage,
    isSportsLivePage,
    isTopListPage,
    setSelectedCountry,
} from "../utils/Util";
import StreamList from "./program/list/StreamList";
import ProgramSearchTemplate from "./program/ProgramSearchTemplate";
import {COUNTRY_CODE} from "../constants/constants";
import TopStreamHome from "./program/toplist/TopStreamHome";

class MainContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }Ï

    getList = () => {
        const {
            match: {params},
        } = this.props;

        if (params.country && COUNTRY_CODE.includes(params.country.toUpperCase())) {
            setSelectedCountry(params.country.toUpperCase())
        }

        if (isTopListPage()) {
            return <TopStreamHome/>
        } else if (params.type || params.personName || params.tag || isSearchPage() || isSportsLivePage() || isProviderDetailPage()) {
            return <StreamList q={params.q} streamType={params.type} tag={params.tag} personName={params.personName}
                               genreName={params.genreName}/>
        }
        return <StreamHome/>
    };


    getProviderThemeClass = () => {
        if (isAnyProviderSelectedOnAllPage()) {
            return getSelectedProviderName() + "-selected"
        }
    }

    componentDidMount() {
    }

    render() {
        const {
            match: {params},
        } = this.props;
        return (
            <>

            {/*<ProgramRecommendations/>*/}
            {/*<ProvidersList/>*/}
            {/*{getHeaderMobileAds()}*/}
            <div className={`container ${this.getProviderThemeClass()} selected-provider`}>
                <div className="row">
                    <div className="col">
                        {
                            this.getList()
                        }
                    </div>
                </div>
                <ProgramSearchTemplate q={params.q}/>
            </div>
            </>
        );
    }
}

export default MainContent;
