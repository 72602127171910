import React from 'react';
import MainContent from '../MainContent';
import Register from '../user/Register';
import Settings from '../userPages/Settings';
import {Route, Switch} from 'react-router-dom';
import PageContent from "../staticPages/Content";
import BlogDetail from "../blog/BlogDetail";

import ProgramDetail from '../program/ProgramDetail'
import ProgramsNews from "../program/news/ProgramsNews";
import MyFavouriteStreamPrograms from "../program/MyFavouriteStreamPrograms";
import RestPassword from "../user/ResetPassword";
import Play from "../staticPages/play";


class MainRoutes extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path={["/:country/page/about", "/page/about"]} render={() => {
                    return <PageContent type='STREAMLY_ABOUT_US'/>
                }}/>
                <Route exact path={["/:country/page/terms", "/page/terms"]} render={() => {
                    return <PageContent type='STREAMLY_TERMS'/>
                }}/>
                <Route exact path={["/:country/page/cookies", "/page/cookies"]} render={() => {
                    return <PageContent type='STREAMLY_COOKIES'/>
                }}/>
                <Route exact path={["/:country/Favourites", "/Favourites"]} component={MyFavouriteStreamPrograms}/>
                <Route exact path={["/:country/User/Create", "/User/Create"]} component={Register}/>
                <Route exact path={["/:country/User/Password/Reset", "/User/Password/Reset"]} component={RestPassword}/>
                <Route exact path={["/:country/User/Settings", "/User/Settings"]} component={Settings}/>
                <Route exact path={["/:country/User/News", "/User/News"]} component={ProgramsNews}/>

                <Route exact
                       path={["/:country/title/:provider/:season/:friendlyUrl", "/title/:provider/:season/:friendlyUrl"]}
                       component={ProgramDetail}/>
                <Route exact path={["/:country/title/:provider/:friendlyUrl", "/title/:provider/:friendlyUrl"]}
                       component={ProgramDetail}/>
                <Route exact path={["/:country/artikel/:friendlyUrl", "/artikel/:friendlyUrl"]} component={ProgramDetail}/>

                <Route exact path={["/:country/page/play", "/page/play"]} component={Play}/>
                <Route exact path={["/:country/sports", "/sports"]} component={MainContent}/>
                <Route exact path={["/:country/person/:personName", "/person/:personName"]} component={MainContent}/>
                <Route exact path={["/:country/tag/:tag", "/tag/:tag"]} component={MainContent}/>
                <Route exact path={["/:country/genre/:type/:genreName", "/genre/:type/:genreName"]}
                       component={MainContent}/>
                <Route exact path={["/:country/genre/provider", "/genre/provider"]} component={MainContent}/>
                <Route exact path={["/:country/genre/:type", "/genre/:type"]} component={MainContent}/>
                <Route exact exact path={["/:country/Search/:q", "/Search/:q"]} component={MainContent}/>
                <Route path={["/:country/:type?", "/:type?"]} component={MainContent}/>
            </Switch>
        )
    }
}

export default MainRoutes;