import React from 'react';
import {Alert, Button, Form} from 'react-bootstrap';
import '../Form.scss'
import http from "../../services/HttpService";
import SimpleReactValidator from 'simple-react-validator';
import loader from '../../images/loader.gif'
import ToastUtil from "../../utils/ToastUtil";
import {toQueryString} from "../../utils/UrlUtil";

class RestPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            isInProgress: false,
            errors: []
        };
        this.validator = new SimpleReactValidator({
            element: (message) => {
                return <span className="error">{message}</span>
            }
        });
    }

    changeHandler = (event) => {
        this.state[event.target.name] = event.target.value;
        this.forceUpdate();
    };

    sendForgotPasswordMail = (event) => {
        event.preventDefault();
        let {email} = this.state;
        if (email) {
            this.toggleInProgress(true);
            http.get("/api/streamly/forgotPassword" + toQueryString({email: email}))
                .then(response => {
                    this.setState({message: 'Skickade glömt lösenord mail till ' + email});
                })
                .catch(error => {
                    ToastUtil.error(error.response.data.errors[0]);
                })
                .finally(() => {
                    this.toggleInProgress(false);
                });
        }
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    render() {
        let {email, message} = this.state;
        return (
            <div className="container">


                <div className="content-block-medium resetPassword">
                    <h2>Glömt ditt lösenord?</h2>
                    <p>Skriv in din e-postadress så skickar vi ett e-postmeddelande till dig med en länk där du kan
                        återställa ditt lösenord.
                    </p>
                    <Form className="form" onSubmit={this.sendForgotPasswordMail} method="POST">
                        <Form.Group>
                            <Form.Label>E-postadress</Form.Label>
                            <Form.Control type={"email"} name="email" value={email}
                                          onChange={this.changeHandler}/>
                            {this.validator.message('email', email, 'required')}
                            {message ? <Alert variant={'success'}>
                                {message}
                            </Alert> : null}
                        </Form.Group>
                        <Form.Group>
                            <Button variant="primary" type="submit" className="cyan-background">
                                Skicka
                                &nbsp;{this.state.isInProgress ?
                                <img src={loader}/> : ''}
                            </Button>
                        </Form.Group>
                    </Form>
                </div>


            </div>
        )
    }
}

export default RestPassword