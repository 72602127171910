import React from 'react';
import loader from '../../images/loader.gif'
import {getParams, redirectTo} from "../../utils/UrlUtil";
import http from "../../services/HttpService";

class Oauth2Success extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    saveAuthDetail = () => {
        let token = getParams("token");
        localStorage.setItem("jtoken", token);
        http.get("/api/users/me")
            .then((response) => {
                let {user} = response.data.content;
                if (user && user.username) {
                    localStorage.setItem("user", JSON.stringify(user));
                }
            })
            .catch((error) => {
                console.log(error.toJSON)
            })
            .finally(() => {
                redirectTo("/")
            })
    };

    componentDidMount() {
        this.saveAuthDetail();
    }

    render() {


        return (<div>
            <div className={"text-center"}>
                <br/>
                <br/>
                <br/>
                <img src={loader}/> Please wait
            </div>
        </div>)
    }
}

export default Oauth2Success