import React from 'react';
import ReactDom from 'react-dom';
import App from './components/App';
import {Provider} from 'react-redux'
import store from './components/store/index'

ReactDom.render(
    <Provider store={store}>
        <App/>
    </Provider>, document.querySelector('#root')
);