import React, {useEffect, useState} from "react";
import "../blog/blogListing.scss";
import "./streamDetail.scss";
import 'moment/locale/sv'
import {redirectTo} from "../../utils/UrlUtil";

const ProgramSearchTemplate = (props) => {

    let [q, setQ] = useState(props.q);

    const changeHandler = (event) => {
        setQ(event.target.value)
    };

    const submit = (event) => {
        event.preventDefault();
        if (q) {
            redirectTo("/Search/" + q)
        } else {
            redirectTo("/Stream")
        }
    };

    useEffect(() => {
    }, []);

    return (
        <>
        <div className="content-block">
            <div className="search-block">
                <h2>Vad letar du efter?</h2>
                <p>Sök efter titel, skådespelare, regissör, år, mm.</p>
                <form onSubmit={submit}>
                    <input type="text" id="q" name="q" onChange={changeHandler} value={q} autoComplete="off"
                           className="footer-search-field"/>
                    <input type="submit" value="Sök"/>
                </form>
            </div>
        </div>
        </>
    );
};
export default ProgramSearchTemplate;
