import React from "react";
import './MainContent.scss';
import './common.scss';
import {getCurrentUser, getSelectedCountry, openContactUsModal, openLoginModal} from "../utils/Util";
import './SidePanel.scss';
import {connect} from "react-redux";
import {isDesktop} from "../utils/DeviceUtil";
import TopNavigation from "./TopNavigation";
import avatar from "../images/avatars/generic-user.svg"
import IsNotLoggedIn from "./user/IsNotLoggedIn";
import IsLoggedIn from "./user/IsLoggedIn";

class SidePanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        let user = getCurrentUser();
        let {mainMenu = {}} = this.props;
        let country = getSelectedCountry().toLowerCase();
        return (
            <div className={`${mainMenu.isOpen ? 'sidebar-expanded' : ''} sidebar`}>
                <IsLoggedIn>
                    <button className="user-info-button">
                        <img src={avatar} className="user-icon" alt={user.fullName}/>
                        <div className="user-info">
                            <p className="user-name">
                                {user.fullName}

                            </p>
                            <p className="current-profile">
                                {user.lastName}
                            </p>
                        </div>
                    </button>
                </IsLoggedIn>

                {!isDesktop() ?
                    <div className="mobile-top-nav">
                        <TopNavigation/>
                    </div> : ''
                }

                <nav className="sidebar-nav">
                    <IsLoggedIn>
                        <a href={`/${country}/Favourites`} className="favourites">
                            Mina favoriter
                        </a>
                        <a href={`/${country}/User/Settings`} className="settings">Inställningar</a>
                    </IsLoggedIn>
                    <a href={`/${country}/page/about`} className="help">Om Streamly</a>
                    <IsNotLoggedIn>
                        <a href={`/${country}/User/Create`} className="profile">Skapa konto</a>
                        <a href="/login" onClick={(e) => {
                            e.preventDefault();
                            openLoginModal()
                        }} className="login login-button header-login">Logga
                            in</a>
                    </IsNotLoggedIn>
                    <IsLoggedIn>
                        <a href="/logout" target={"_self"} className="login login-button header-login"
                           data-track="Sidebar|Interaction|Login">Logga
                            ut</a>
                    </IsLoggedIn>
                    <a href={`/${country}/contactUs`} onClick={(e) => {
                        e.preventDefault();
                        openContactUsModal()
                    }} className="suggestion suggest-button">
                        Kontakta oss
                    </a>
                </nav>
                {/* <SidePanelPrograms/> */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {mainMenu: state.mainMenu}
};

export default connect(mapStateToProps)(SidePanel);
