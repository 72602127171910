import React from 'react';
import '../ProgramsLists.scss'
import _ from 'lodash';
import {
    findOrGetDefaultTranslation,
    getProgramImage,
    getProviderLabel,
    getSelectedCountry,
    getSettings,
    getStreamStartTime,
    isLive,
    isLoggedIn,
    isProgramExpired,
    isSport,
    isSportsLivePage,
    isSportsPage
} from "../../utils/Util";
import {getStreamDetailUrl} from "../../utils/UrlUtil";
import UserAction from "./UserAction";
import StreamPlay from "./StreamPlay";
import http from "../../services/HttpService";
import store from "../store/index";
import {toggleLoginModal} from "../action/loginModalAction";

class StreamCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showPlay: false,
            reminder: props.reminder
        };
    }

    openLogin = () => {
        store.dispatch(toggleLoginModal(true));
    };

    getInfoTextAsList = (program) => {
        let infoList = [];
        if (program) {
            if (_.size(program.providers) > 0) {
                _.filter(program.providers, ["country", getSelectedCountry()]).forEach((provider, index) => {
                    infoList.push(<span className="provider">{getProviderLabel(provider.provider)} </span>);
                    infoList.push(<span className={"divider"}>|</span>)
                });
            }
            if (_.size(program.genres) > 0) {
                program.genres.forEach((genre, index) => {
                    infoList.push(<span>{genre.name}</span>);
                    infoList.push(<span className={"divider"}>|</span>)
                });
            }
            if (program.rating) {
                infoList.push(<span>Betyg: {program.rating}</span>);

            }
        }
        return infoList;
    };

    toggleReminder = (e) => {
        e.stopPropagation();
        if (isLoggedIn()) {
            let {program = {}} = this.props;
            http
                .get("/api/streamly/reminders/toggle/" + program.id)
                .then((response) => {
                    let {reminder} = response.data.content;
                    this.setState({reminder});
                })
                .catch((error) => {
                    console.log(error);
                })
        } else {
            this.openLogin();
        }

    };

    onPlay = () => {
        this.setState({showPlay: true});
    };

    closePlay = () => {
        this.setState({showPlay: false})
    };

    UNSAFE_componentWillMount() {
    }

    render() {

        let {program = {}, favourite, hideUserActions, rank} = this.props;
        let {showPlay, reminder} = this.state;
        let selectedCountry = getSelectedCountry();
        let translation = findOrGetDefaultTranslation(program, selectedCountry);
        let setting = getSettings(program, selectedCountry);

        return (
            <div className="relative">
                {setting.rank > 0 ? <h1 className={'rank'}>{setting.rank}</h1> : null}
                <div className={`channel  item ${isLive(program) ? 'is-live' : ''}`} style={{
                    backgroundImage: `url(${getProgramImage(program)})`
                }}>
                    {/* <h2 className="uppName">{translation.title}</h2> */}

                    <a className="back" href={getStreamDetailUrl(program)}>
                        {isSport(program) ? <div className={`live-overlay`}>
                            {isLive(program) ? <span class="live-text">Live</span> : null}
                            <span className="time-text">{getStreamStartTime(program, isSportsPage())}</span>
                        </div> : null}
                        <div className="overlay">
                            {isSportsLivePage() && !isProgramExpired(program) ?
                                <button class={`remind-button small-card-remind-button ${reminder ? 'active' : ''}`}
                                        onClick={this.toggleReminder}
                                        title="Påminn mig 10 min innan start"></button> : null}
                            {program.id && !hideUserActions ?
                                <UserAction showPlayBtn={!!program.videoUrl} onPlay={this.onPlay} programId={program.id}
                                            favourite={favourite}/> : null}

                            <div className="info-text">
                                <h2 className="name">{translation.title}</h2>
                                <div className="text-labels">
                                    {this.getInfoTextAsList(program).map((element) => {
                                        return element
                                    })}
                                </div>
                            </div>
                        </div>
                        {showPlay ? <StreamPlay show={showPlay} onHide={this.closePlay} program={program}/> : null}
                    </a>
                </div>
            </div>
        )

    }
}

export default StreamCard