import React from 'react';
import {ellipsis, isArticleDetailPage, isArticleListPage} from "../utils/Util";
import http from "../services/HttpService";
import {toQueryString} from "../utils/UrlUtil";
import _ from 'lodash'

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {searchTerm: props.searchTerm || '', placeholder: 'Sök', programs: []};
        this.timer = null;
        this.divRef = React.createRef();
    }

    onInputChange = (e) => {
        this.setState({searchTerm: e.target.value}, () => {
            clearTimeout(this.timer);
            this.timer = setTimeout(this.autoComplete, 500);
        });
        if (this.props.onSearchChange) {
            this.props.onSearchChange(e.target.value)
        }
    };

    search = () => {
        this.props.onFormSubmit(this.state.searchTerm);
    };

    onFormSubmit = (e) => {
        e.preventDefault();
        this.search()
    };

    addActive = () => {
        this.divRef.current.classList.add('search-active')
    };

    onAutoCompeteSelect = (text) => {
        this.setState({searchTerm: text}, this.search);
    };

    autoComplete = () => {
        let {searchTerm} = this.state;
        let params = {
            q: searchTerm,
            excludeProgramsType: ["episode"],
            size: 30
        };
        if (!isArticleListPage()) {
            params.excludeProgramsType.push("article")
        }
        http
            .get("/api/es/programs/autoComplete" + toQueryString(params))
            .then((response) => {
                let {programs, totalElements} = response.data.content;
                this.setState({programs})
            })
            .catch((error) => {
                console.log(error);
            });
    };

    getArtistName = (program) => {
        let {searchTerm} = this.state;
        let name = null;
        if (searchTerm) {
            name = _.find(program.actors, (actor) => {
                return actor.includes(searchTerm)
            });
            if (!name) {
                name = _.find(program.directors, (actor) => {
                    return actor.toLowerCase().includes(searchTerm.toLowerCase())
                })
            }
        }
        if (name) {
            return <span>{name} /</span>;
        }
    };

    componentDidMount() {
        if (this.state.searchTerm) {
            this.addActive();
        }
        if (isArticleListPage() || isArticleDetailPage()) {
            this.setState({placeholder: "Sök Artiklar"})
        }
    }

    render() {
        let {placeholder, programs} = this.state;
        return (
            <div ref={this.divRef} className="search" onClick={this.addActive}>
                <form onSubmit={this.onFormSubmit} className="form-inline">
                    <input className="form-control ml-auto" type="search" placeholder={placeholder}
                           value={this.state.searchTerm} aria-label="search" onChange={this.onInputChange}/>
                    <button className="btn" type="submit"></button>
                    <span className="icon">
                          
                        </span>
                </form>
                <ul className="autocomplete-menu">
                    {
                        programs.map((program, index) => {
                            return <li key={index} onClick={() => {
                                this.onAutoCompeteSelect(program.title)
                            }}>{this.getArtistName(program)} {ellipsis(program.title, 100)}</li>
                        })
                    }
                </ul>
            </div>
        )
    }
}

export default Search;