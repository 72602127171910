import React, {useEffect, useState} from "react";
import {getParams} from "../../../utils/UrlUtil";
import http from "../../../services/HttpService";
import "../streamListing.scss";
import _ from 'lodash'
import {getSelectedCountry, getSettings, setProviderAndRedirectTo} from "../../../utils/Util";
import StreamCard from "../StreamCard";
import $ from "jquery"

import Slider from "react-slick";


const TopStreamBlock = (props) => {
    const DEFAULT_STREAM_SIZE = 8;
    const [programs, setPrograms] = useState([]);
    const [favourites, setFavourites] = useState([]);
    const size = props.size || DEFAULT_STREAM_SIZE;
    const {streamType, providers, containerClass, heading, viewAllUrl} = props;
    const loadFavourites = () => {
        http
            .get("/api/es/favourites/streamly/me")
            .then((response) => {
                let {favourites} = response.data.content;
                setFavourites(favourites || []);
            })
            .catch((error) => {
                console.log(error);
            })
    };

    const viewAll = (url) => {
        setProviderAndRedirectTo(providers[0], url)
    };

    const loadPrograms = () => {
        let params = getParams();
        let selectedCountry = getSelectedCountry();
        if (_.size(providers) > 0) {
            params.set("providers", _.map(providers, "name"));
        }
        params.set("streamType", streamType);
        params.set("sorting", "topList");
        params.set("active", true);
        params.set("size", size);
        params.set("excludeProgramsType", ["article", "episode"]);
        params.set("country", selectedCountry);
        params.set("seriesProgram", false);

        http
            .get("/api/es/programs?" + params.toString())
            .then((response) => {
                let {programs} = response.data.content;
                programs = _.orderBy(programs, program => {
                    let setting = getSettings(program, selectedCountry);
                    if (setting.rank) {
                        return setting.rank;
                    }
                    return 99;
                }, ["asc"]);
                programs = _.uniqBy(programs, program => {
                    let setting = getSettings(program, selectedCountry);
                    if (setting.rank) {
                        return setting.rank;
                    }
                    return 0;
                }, ["asc"]);
                setPrograms(programs);
            })
            .catch((error) => {
                console.log(error);
            })
    };

    const getFavouriteByProgramId = (programId) => {
        if (_.size(favourites) > 0) {
            return _.find(favourites, ["programId", programId])
        }
    };

    useEffect(() => {
        for (let i = 1; i <= 40; i++) {
            programs.push({category: {}})
        }
        setPrograms(programs);
        loadFavourites();
        loadPrograms();
    }, []);

    if (_.size(programs) === 0) {
        return <div/>
    }
    var settings = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 5.17,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4.25,
                    slidesToScroll: 2

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    slidesToScroll: 1,
                    swipeToSlide: true,
                    swipe: true,
                    infinite: true,
                    focusOnSelect: true,
                    touchMove: true,
                }
            }
        ]
    };
    $('.slider-parent .flickfeed').on('init', function () {
        var currentfirst = $(this).find('.slick-active').first();
        $(currentfirst).addClass('firster');
        // console.log('thishere');
        var currentlast = $(this).find('.slick-active').last();
        $(currentlast).addClass('laster');
    });


    $(".slider-parent .slick-slide").mouseenter(function () {

        if ($(this).hasClass("firster")) {
            //  console.log('firster');
            var hoverslide = $(this);
            $(hoverslide).nextAll().addClass('furthernextslides');
            //  $(hoverslide).prevAll().addClass('prevslides');

        } else if ($(this).hasClass("laster")) {
            var hoverslide = $(this);
            $(hoverslide).prevAll().addClass('furtherprevslides');
        } else {
            var hoverslide = $(this);
            $(hoverslide).nextAll().addClass('nextslides');
            $(hoverslide).prevAll().addClass('prevslides');
        }


    });

    $(".slider-parent .slick-slide").mouseleave(function () {
        $(this).parent().find(".slick-slide").removeClass('nextslides');
        $(this).parent().find(".slick-slide").removeClass('prevslides');
        $(this).parent().find(".slick-slide").removeClass('furthernextslides');
        $(this).parent().find(".slick-slide").removeClass('furtherprevslides');
    });
    return (
        <div className={containerClass}>
            <h2 className="headingWithBorder">
                {heading}
                <a href={viewAllUrl} onClick={(e) => {
                    e.preventDefault()
                    viewAll(viewAllUrl);
                }} className="view-all-btn">Visa alla </a></h2>

            <div className={`program-list slider-parent`}>
                <Slider className="flickfeed"  {...settings}>
                    {
                        programs.map((program, index) => {
                            return (
                                <div>
                                    <StreamCard program={program}
                                                favourite={getFavouriteByProgramId(program.id)}/>
                                </div>
                            );
                        })
                    }
                </Slider>
            </div>
        </div>
    );
};

export default TopStreamBlock;
