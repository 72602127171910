import {combineReducers} from 'redux';
import mainMenuReducer from './mainMenuReducer';
import loginModalReducer from "./loginModalReducer";
import contactUsModalReducer from "./contactUsModalReducer";

const rootReducer = combineReducers({
    mainMenu: mainMenuReducer,
    loginModal: loginModalReducer,
    contactUsModal: contactUsModalReducer
});
export default rootReducer;