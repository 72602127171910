import React, {useState} from "react";
import http from "../../services/HttpService";
import {isLoggedIn} from "../../utils/Util";
import store from "../store/index";
import {toggleLoginModal} from "../action/loginModalAction";

const UserAction = (props) => {
    const [favourite, setFavourite] = useState(props.favourite || {});

    let {pageType = {}, showPlayBtn} = props;

    const openLogin = () => {
        store.dispatch(toggleLoginModal(true));
    };

    const saveFavourite = () => {
        if (props.programId) {
            favourite.programId = props.programId;
            http
                .post("/api/es/favourites/streamly", favourite).then((response) => {
                let {favourite} = response.data.content;
                setFavourite(favourite || {});
            }).catch((error) => {
                console.log(error);
            });
        }
    };


    const play = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let {onPlay} = props;
        if (onPlay) {
            onPlay()
        }
    };

    const like = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (isLoggedIn()) {
            favourite.like = !favourite.like;
            if (favourite.like) {
                favourite.dislike = false
            }
            saveFavourite();
        } else {
            openLogin();
        }
    };

    const dislike = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (isLoggedIn()) {
            favourite.dislike = !favourite.dislike;
            if (favourite.dislike) {
                favourite.like = false
            }
            saveFavourite();
        } else {
            openLogin();
        }
    };

    const watch = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (isLoggedIn()) {
            favourite.watch = !favourite.watch;
            saveFavourite();
        } else {
            openLogin();
        }
    };

    return <>
        <div className="user-action">
            {pageType === 'ProgramDetails' ?
                <span class="title"><strong>Sign in</strong> to create Watchlist</span> : ''}

            {/* <a href="" onClick={like} title="Like"
               className={`like-icon ${favourite.like ? 'liked' : ''}`}/>
            <a href="" onClick={dislike} title="Dislike"
               className={`dislike-icon ${favourite.dislike ? 'disliked' : ''}`}/>
             */}
            <a href="" onClick={watch} title="Add to WatchList"
               className={`addTOlist-icon2 ${favourite.watch ? 'watched' : ''}`}/>
            {showPlayBtn ? <a href="" onClick={play} title="Visa trailer"
                              className={`play-icon`}/> : null}
        </div>
        </> 
};

export default UserAction;