import React, {useEffect, useState} from "react";
import '../slider.scss'
import {toQueryString} from "../../utils/UrlUtil";
import http from "../../services/HttpService";
import _ from 'lodash'
import StreamCard from "../program/StreamCard";
import {findFavouriteByProgramId, getLiveEndDateFilter, getLiveStartDateFilter} from "../../utils/Util";

const StreamCarousel = (props) => {

    const [programs, setPrograms] = useState([]);
    const heading = props.heading || 'Also Liked';
    const favourites = props.favourites || [];
    const isArticlePage = props.isArticlePage || false;
    const isSportPage = props.isSportPage || false;

    const loadPrograms = () => {
        let params = {
            active: true,
            order: "desc",
            sort: "recentlyAdded",
            size: 8,
            excludeProgramsType: ["episode"],
            seriesProgram: false
        };

        if (isSportPage) {
            params.liveStartDate = getLiveStartDateFilter(0);
            params.liveEndDate = getLiveEndDateFilter(7);
        }

        if (!isArticlePage) {
            params.excludeProgramsType.push("article")
        }

        if (props.order) {
            params.order = props.order
        }

        if (props.sort) {
            params.sort = props.sort
        }

        if (props.seasonFriendlyUrl) {
            params.seasonFriendlyUrl = props.seasonFriendlyUrl;
            params.seriesProgram = true;
        }

        if (_.size(props.providers) > 0) {
            params.providers = props.providers;
        }

        if (_.size(props.genres) > 0) {
            params.genres = props.genres;
        }

        if (props.size) {
            params.size = props.size;
        }

        if (props.country) {
            params.country = props.country;
        }

        http.get("/api/es/programs" + toQueryString(params))
            .then((response) => {
                let {programs, totalElements} = response.data.content;
                setPrograms(programs);
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
            })
    };

    useEffect(() => {
        loadPrograms();
    }, []);

    if (programs.length > 0) {
        return (
            <div className="sliderSection">
                <h2> {heading}</h2>
                <div className="stream-listing program-list slider-parent">
                    {
                        programs.map((program, index) => {
                            return <StreamCard program={program}
                                               favourite={findFavouriteByProgramId(favourites, program.id)}/>
                        })
                    }
                </div>
            </div>
        );
    }
    return <div/>
};


export default StreamCarousel;