let Translation = {
    "SE": {
        "nav.film": "Film",
        "nav.series": "Serier",
        "nav.program": "Program",
        "nav.sport": "Sport",
        "nav.free": "Gratis",
        "nav.documentary": "Dokumentar",
        "nav.children": "Barn",
        "nav.article": "Artiklar",
        "nav.toplist":"Topplistor",
        "top.ten.film":"Topp 10 Filmer",
        "top.ten.series":"Topp 10 Serier",
        "play.trailer":"Spela trailer",
        "more.info":"Mer information",
        "top.list":"Topplista",
        "new.release":"Nyheter",
        "trending":"Trendigt",
        "popular":"Populär",
        "popular.now":"Populär just nu",
    },
    "FI": {
        "nav.film": "Elokuva",
        "nav.series": "sarja",
        "nav.program": "Ohjelmoida",
        "nav.sport": "Urheilu",
        "nav.free": "Vapaa",
        "nav.documentary": "Dokumentti",
        "nav.children": "Lapset",
        "nav.article": "Artikla",
        "nav.toplist":"Suosituimmat listat"
    },
    "DK": {
        "nav.film": "Film",
        "nav.series": "Serie",
        "nav.program": "Program",
        "nav.sport": "Sport",
        "nav.free": "Gratis",
        "nav.documentary": "Dokumentar",
        "nav.children": "Børn",
        "nav.article": "Artikel",
        "nav.toplist":"Toplister"
    },
    "NO": {
        "nav.film": "Film",
        "nav.series": "Serie",
        "nav.program": "Program",
        "nav.sport": "Sport",
        "nav.free": "Gratis",
        "nav.documentary": "Dokumentar",
        "nav.children": "Barn",
        "nav.article": "Artikkel",
        "nav.toplist":"Topplister"
    },
    "DEFAULT": {
        "nav.film": "Film",
        "nav.series": "Series",
        "nav.program": "Program",
        "nav.sport": "Sport",
        "nav.free": "Free",
        "nav.documentary": "Documentary",
        "nav.children": "Children",
        "nav.article": "Article",
        "nav.toplist":"Top List",
        "top.ten.film":"Top 10 Movies",
        "top.ten.series":"Top 10 Series",
        "play.trailer":"Play Trailer",
        "more.info":"More Info",
        "top.list":"Top List",
        "new.release":"New Release",
        "trending":"Trending",
        "popular":"Popular",
        "popular.now":"Popular Now"
    }
}

export const getTranslatedText = (country, key) => {
    if (!Translation.hasOwnProperty(country)) {
        return Translation["DEFAULT"][key]
    }
    let text = Translation[country][key];
    if (!text) {
        text = Translation["DEFAULT"][key]
    }
    return text;
}