import React from "react";
import "./Header.scss";
import TopNavigation from "../TopNavigation";
import Search from "../Search";
import logo from "../../images/logo.svg";
import {getParams, redirectTo} from "../../utils/UrlUtil";
import {doLogout, getSelectedCountry, setSelectedProviders,} from "../../utils/Util";
import store from '../store/index'
import {toggleMainMenu} from "../action/mainMenuAction";
import {isDesktop} from "../../utils/DeviceUtil";
import {toggleLoginModal} from "../action/loginModalAction";
import IsLoggedIn from "../user/IsLoggedIn";
import IsNotLoggedIn from "../user/IsNotLoggedIn";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: getParams("q") || "",
            isMainMenuOpen: false
        };
    }

    onSearchSubmit = (searchTerm) => {
        setSelectedProviders([])
        if (searchTerm) {
            redirectTo("/Search/" + searchTerm)
        } else {
            redirectTo("/Stream")
        }
    };

    toggleMenu = () => {
        let {isMainMenuOpen} = this.state;
        store.dispatch(toggleMainMenu(!isMainMenuOpen));
        this.setState({isMainMenuOpen: !isMainMenuOpen});
    };

    openLoginModal = () => {
        store.dispatch(toggleLoginModal(true));
    };

    getLogo = () => {
        let _logo = logo;
        return <img src={_logo} alt={"Streamly.com"}/>;
    };

    render() {
        let {searchTerm} = this.state;
        return (
            <header className="header container">
                <div className="row">
                    <div className="col-xs-10 col-sm-12 col-md-2 col-lg-2 padding0 d-flex">
                        <div className="hamburgerMenu" onClick={this.toggleMenu}>
                            <span className="hamburgerIcon" id="sidebar-nav-button"></span>
                        </div>
                        <h1>
                            <a href={`/${getSelectedCountry().toLowerCase()}`}
                               className="logo"
                               target="_self"
                               title="Streamly">
                                {this.getLogo()}
                            </a>
                        </h1>
                    </div>

                    {isDesktop() ?
                        <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 padding0 mobileNone">
                            <TopNavigation/>
                        </div> : ''
                    }

                    <div className="col-xs-2 col-sm-12 col-md-1 col-lg-1 padding0 mobileView">
                        <div className="dflex">
                            {/*<StreamCountry/>*/}
                            <IsNotLoggedIn>
                                <button className="header-login login-button" onClick={this.openLoginModal}>Logga in
                                </button>
                            </IsNotLoggedIn>
                            <IsLoggedIn>
                                <button className="header-login login-button" onClick={doLogout}>Logga ut
                                </button>
                            </IsLoggedIn>
                            <Search
                                onFormSubmit={this.onSearchSubmit}
                                searchTerm={searchTerm}
                            />
                        </div>
                    </div>
                </div>
            </header>

        );
    }
}

export default Header;
