import React from "react";
import "./filterBar.scss";
import "../../constants/constants";
import {getParams} from "../../utils/UrlUtil";
import {convertToQueryString} from "../../utils/Util";
import {TIME_ZONE} from "../../utils/DateUtil";
import moment from 'moment-timezone'

class SportsDays extends React.Component {
    constructor() {
        super();
        let params = getParams();
        this.state = {
            days: [],
            selectedDay: parseInt(params.get("day")) || 0,
            isOpen: false
        };
    }


    refreshPage = () => {
        window.location.reload(false);
    };

    toggleDay = (day) => {
        let params = getParams();
        if (day === 0) {
            params.delete("day")
        } else {
            params.set("day", day)
        }
        window.location.href = window.location.pathname + convertToQueryString(params)
    };

    toggleMenu = () => {
        this.setState({isOpen: !this.state.isOpen})
    };

    getDayName = (day) => {
        const days = {
            '0': 'Idag',
            '-1': 'Igår',
            '1': 'I morgon'
        };
        let name = days[day];
        if (!name) {
            name = moment.tz(TIME_ZONE).add(day, "days").format("dddd")
        }
        return name
    };

    componentDidMount() {
        let {days} = this.state;
        for (let i = -1; i < 7; i++) {
            days.push(i)
        }
    }

    render() {
        let {days, isOpen, selectedDay} = this.state;

        return (
            <>
            <button class={`menu-button select-source-menu-button ${isOpen ? 'extended' : ''}`}
                    onClick={this.toggleMenu}>
                {this.getDayName(selectedDay)}
            </button>

            <div className="select-source select-drop-down"
                 style={{'display': isOpen ? 'block' : 'none'}}>
                <div className="even-columns">
                    {
                        days.map((day) => {
                            return <>
                            <input type="checkbox"
                                   checked={day === selectedDay ? 'checked' : ''}
                                   onClick={() => {
                                       this.toggleDay(day)
                                   }
                                   } className="genre-checkbox" id={day}
                                   value={day}/>
                            <label for={day}>{this.getDayName(day)}</label>
                            </>
                        })
                    }
                </div>
            </div>
            </>
        );
    }
}

export default SportsDays;
