import React from "react";
import http from "../../../services/HttpService";
import {getParams} from "../../../utils/UrlUtil";
import {
    findFavouriteByProgramId,
    findReminderByProgramId,
    getLiveEndDateFilter,
    getLiveStartDateFilter,
    getSelectedCountry,
    getSelectedProviders,
    getSettings,
    isSportsLivePage
} from "../../../utils/Util";
import _ from 'lodash'
import StreamCard from "../StreamCard";
import Slider from "react-slick";
import $ from "jquery";

class StreamSliderBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            programs: [],
            favourites: [],
            reminders: [],
            pageCount: 0,
            totalElements: 0
        };
    }

    settings = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 3.4,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4.17,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.1,
                    centerMode: true,
                    slidesToScroll: 1,
                    swipeToSlide: true,
                    swipe: true,
                    infinite: true,
                    focusOnSelect: true,
                    touchMove: true,
                }
            }
        ]
    };


    loadFavourites = () => {
        http
            .get("/api/es/favourites/streamly/me")
            .then((response) => {
                let {favourites} = response.data.content;
                this.setState({favourites});
            })
            .catch((error) => {
                console.log(error);
            })
    };

    loadReminders = () => {
        if (isSportsLivePage()) {
            http
                .get("/api/streamly/reminders/me")
                .then((response) => {
                    let {reminders} = response.data.content;
                    this.setState({reminders});
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };

    loadPrograms = () => {
        let params = getParams();
        let selectedCountry = getSelectedCountry();
        let {sorting, streamType, size, newRelease, trending, popular, topList} = this.props;
        if (isSportsLivePage()) {
            streamType = "sports";
            let day = params.get("day") || 0;
            params.set("liveStartDate", getLiveStartDateFilter(day));
            params.set("liveEndDate", getLiveEndDateFilter(day));
        }
        switch (streamType) {
            case "movies":
                streamType = 'movie';
                break;
            case 'series':
                streamType = 'tv-show';
                break;
            case 'shows':
                streamType = 'tv-show';
                params.set("types", ["shows"]);
                break;
            case 'sports':
                streamType = 'live';
                break;
            case 'documentaries':
                params.set("genre", "documentary");
                break;
            case 'free':
                params.set("free", true);
                break;
            case 'kids':
                params.set("genre", "kids");
                break;
            case 'articles':
                streamType = 'article';
                break
        }
        if (sorting) {
            params.set("sorting", sorting);
        }
        if (newRelease) {
            params.set("newRelease", true)
        }
        if (trending) {
            params.set("trending", true)
        }
        if (popular) {
            params.set("popular", true)
        }
        if (topList) {
            params.set("topList", true)
        }
        if (streamType) {
            params.set("streamType", streamType);
        }

        params.set("active", true);
        params.set("size", size);
        if (streamType !== 'article') {
            params.set("excludeProgramsType", ["article", "episode"]);
            let providers = getSelectedProviders();
            if (_.size(providers) > 0) {
                params.set("providers", _.join(_.map(providers, "name"), ","))
            }
        }
        params.set("seriesProgram", false);
        params.set("country", selectedCountry);

        http
            .get("/api/es/programs?" + params.toString())
            .then((response) => {
                let {programs} = response.data.content;
                if (sorting === "topList") {
                    programs = _.orderBy(programs, program => {
                        let setting = getSettings(program, selectedCountry);
                        if (setting.rank) {
                            return setting.rank;
                        }
                        return 99;
                    }, ["asc"]);
                    programs = _.uniqBy(programs, program => {
                        let setting = getSettings(program, selectedCountry);
                        if (setting.rank) {
                            return setting.rank;
                        }
                        return 0;
                    }, ["asc"]);
                }
                this.setState({programs});
            })
            .catch((error) => {
                console.log(error);
            });
    };

    getFavouriteByProgramId = (programId) => {
        const {favourites} = this.state;
        return findFavouriteByProgramId(favourites, programId);
    };

    getReminderByProgramId = (programId) => {
        const {reminders} = this.state;
        return findReminderByProgramId(reminders, programId);
    };

    initSlider = () => {
        $('.slider-parent .flickfeed').on('init', function () {
            var currentfirst = $(this).find('.slick-active').first();
            $(currentfirst).addClass('firster');
            // console.log('thishere');
            var currentlast = $(this).find('.slick-active').last();
            $(currentlast).addClass('laster');
        });


        $(".slider-parent .slick-slide").mouseenter(function () {

            if ($(this).hasClass("firster")) {
                //  console.log('firster');
                var hoverslide = $(this);
                $(hoverslide).nextAll().addClass('furthernextslides');
                //  $(hoverslide).prevAll().addClass('prevslides');

            } else if ($(this).hasClass("laster")) {
                var hoverslide = $(this);
                $(hoverslide).prevAll().addClass('furtherprevslides');
            } else {
                var hoverslide = $(this);
                $(hoverslide).nextAll().addClass('nextslides');
                $(hoverslide).prevAll().addClass('prevslides');
            }


        });

        $(".slider-parent .slick-slide").mouseleave(function () {
            $(this).parent().find(".slick-slide").removeClass('nextslides');
            $(this).parent().find(".slick-slide").removeClass('prevslides');
            $(this).parent().find(".slick-slide").removeClass('furthernextslides');
            $(this).parent().find(".slick-slide").removeClass('furtherprevslides');
        });

    }

    componentDidMount() {
        this.loadFavourites();
        this.loadReminders();
        this.loadPrograms();
    }


    render() {
        let {programs} = this.state;
        let {viewAllUrl, heading, containerClass} = this.props;
        if (_.size(programs) === 0) {
            return <div/>
        }

        return (<div className={`${containerClass}`}>
                <span className="section-title">{heading}</span>
                <div>
                    <div className={`program-list slider-parent`}>
                        <Slider className="flickfeed" {...this.settings}>
                            {
                                programs.map((program, index) => {
                                    return <StreamCard program={program} rank={index}
                                                       reminder={this.getReminderByProgramId(program.id)}
                                                       favourite={this.getFavouriteByProgramId(program.id)}/>
                                })
                            }
                        </Slider>
                    </div>
                </div>
                <a href={viewAllUrl} className="view-all-btn">Visa alla </a>
            </div>
        );
    }
}

export default StreamSliderBlock;
