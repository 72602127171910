export const RECAPTA_KEY = "6LcptlUlAAAAADdaMqqzdPa2iphVvhYyYKH9rOCV";
export const TIMELINE_LAYOUT = 'timeline';
export const TABLE_LAYOUT = 'default';
export const TOTAL_WEEK_DAYS = 7;
export const CONTENT_PAGES = [
    {name: 'About US', value: 'ABOUT_US'},
    {name: 'Help', value: 'HELP'},
    {name: 'Cookies', value: 'COOKIES'},
    {name: 'Advertisement', value: 'ADVERTISEMENT'},
    {name: 'Sitemap', value: 'SITEMAP'}
];
export const BLOG_CHUNK_SIZE = 4;
export const TABLET_BLOG_CHUNK_SIZE = 3;
export const DEFAULT_SIZE = 10;
export const DEFAULT_COUNTRY_CODE = "SE";
export const FULL_ACCESS_PERMISSION = 'FULL_ACCESS';
export const WRITE_BLOG_PERMISSION = 'WRITE_BLOG';
export const WRITE_STREAMLY_PERMISSION = 'WRITE_STREAMLY';
export const SELECTED_COUNTRY_CODE_KEY = "streamlySelectedCountryCode"

export const FEATURE_PERMISSIONS = [
    FULL_ACCESS_PERMISSION,
    WRITE_BLOG_PERMISSION,
    WRITE_STREAMLY_PERMISSION,
];
export const CLIENT = "streamly";

export const COUNTRY_CODE = [
    "SE",
    "DK",
    "NO",
    "FI"
]