import React from 'react';
import '../App.scss';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import MainLayout from "./layouts/MainLayout";
import {logout} from "../utils/Util";
import {redirectTo} from "../utils/UrlUtil";
import 'react-toastify/dist/ReactToastify.css';
import {toast} from 'react-toastify';
import Oauth2Success from "./oauth/Oauth2Success";
import ForgotPassword from "./user/ForgotPassword";
import MyFavouriteStreamPrograms from "./program/MyFavouriteStreamPrograms";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        toast.configure();
    }

    componentDidMount() {
    }

    render() {

        return (<>
                {/*<SelectCountryModal isOpen={!hasCountrySelected()}/>*/}
                <Router>
                    <Switch>
                        <Route exact path={["/:country/logout", "/logout"]} render={() => {
                            logout();
                            redirectTo("/")
                        }}/>
                        <Route exact path="/forgotPassword/:hash" component={ForgotPassword}/>
                        <Route path="/oauth2/success" component={Oauth2Success}/>
                        <Route path="/" component={MainLayout}/>
                    </Switch>
                </Router>
            </>
        )
    }
}

export default App