import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import "../slider.scss";
import {getStreamDetailUrl, redirectToUrl, toQueryString} from "../../utils/UrlUtil";
import http from "../../services/HttpService";
import _ from 'lodash';
import {getProgramTitle, getProviderLabel, getSelectedCountry, isStreamHomePage} from "../../utils/Util";

const ProgramRecommendations = (props) => {
    const [programs, setBlogs] = useState([]);
    let slider;
    const settings = {
        dots: true,
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        fade: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    // dots: true
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    // speed: 5000,
                    autoplay: true,
                    dots: true,
                    speed: 500,
                    // autoplay: true,
                    autoplaySpeed: 3500,
                    // infinite: true,
                    // centerMode: false, 
                    // rtl: true,

                },
            },
        ],
    };

    const next = () => {
        slider.slickNext();
    };

    const previous = () => {
        slider.slickPrev();
    };

    useEffect(() => {
        let params = {
            active: true,
            featured: true,
            size: 10,
            order: "desc",
            sort: "recentlyAdded",
            excludeProgramsType: ["episode", "article"],
            seriesProgram: false,
            country: getSelectedCountry()
        };

        http
            .get("/api/es/programs" + toQueryString(params))
            .then((response) => {
                let {programs, totalElements} = response.data.content;
                setBlogs(_.orderBy(programs, "featuredTabIndex"));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
            });
    }, []);
    if (!(isStreamHomePage())) {
        return <div/>
    } else if (programs.length > 0) {
        return (
            <div className="sliderSection homeSlider">
                {
                    <Slider ref={(c) => (slider = c)} {...settings}>
                        {programs.map((program, index) => {
                            return (
                                <div
                                    className="item"
                                    key={index}
                                    onClick={() => redirectToUrl(getStreamDetailUrl(program))}
                                    // style={{
                                    //     backgroundImage: `url(${program.imageUrl})`
                                    // }}
                                >
                                    {/* <img src={program.imageUrl} className="image"/> */}
                                    <div className="parent-bg" style={{backgroundImage: `url(${program.imageUrl})`}}>
                                        <div className="overlay">
                                            <div className="info">
                                                <h3>{getProgramTitle(program)}</h3>
                                                <span>
                                                    {
                                                        program.providers ? _.filter(program.providers, ["country", getSelectedCountry()]).map((provider) => {
                                                            return <span
                                                                className="provider-badge">{getProviderLabel(provider.provider)}</span>
                                                        }) : null
                                                    }
                                                </span>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            );
                        })}
                    </Slider>
                }

                <div className="controls">
                    <span className="previous-btn" onClick={previous}></span>
                    <span className="next-btn" onClick={next}></span>
                </div>
            </div>
        );
    } else {
        return <div/>;
    }
};

export default ProgramRecommendations;
